import { createActionTypes } from 'utils/action/actionTypes';

export const FETCH_SAP_MONTHLY_DATA = createActionTypes('FETCH_SAP_MONTHLY_DATA');
export const SEND_SAP_INVOICE_B2C_RECORDS_IGST = createActionTypes('SEND_SAP_INVOICE_B2C_RECORDS_IGST');
export const SEND_SAP_INVOICE_B2C_RECORDS_CGST_CGST = createActionTypes('SEND_SAP_INVOICE_B2C_RECORDS_CGST_CGST');
export const DOWNLOAD_INVOICE_B2C = createActionTypes('DOWNLOAD_INVOICE_B2C');
export const SAP_FAULTY_BOOKING_REPORT = createActionTypes('SAP_FAULTY_BOOKING_REPORT');


export const GET_SAP_OCPI_PARTNER_LIST = createActionTypes('GET_SAP_OCPI_PARTNER_LIST');
export const FETCH_SAP_OCPI_CREDS_LIST = createActionTypes('FETCH_SAP_OCPI_CREDS_LIST');
export const ADD_SAP_OCPI_PARTNER_LIST = createActionTypes('ADD_SAP_OCPI_PARTNdER_LIST');
export const UPDATE_SAP_OCPI_PARTNER_LIST = createActionTypes('UPDATE_SAP_OCPI_PARTNER_LIST');
export const DELETE_SAP_OCPI_PARTNER_LIST = createActionTypes('DELETE_SAP_OCPI_PARTNER_LIST');

export const GENERATE_SAP_INVOICES_WITH_GST = createActionTypes('GENERATE_SAP_INVOICES_WITH_GST');
export const FETCH_SAP_INVOICES_WITH_GST = createActionTypes('FETCH_SAP_INVOICES_WITH_GST');
export const DOWNLOAD_SAP_INVOICES_WITH_GST = createActionTypes('DOWNLOAD_SAP_INVOICES_WITH_GST');
export const TRIGGER_LOGITAX_OCPI_INVOICES = createActionTypes('TRIGGER_LOGITAX_OCPI_INVOICES');

