import React, { useState, useEffect } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header';
import Card from 'components/inputs/Card';
import { Col, Row, Button } from 'react-bootstrap';
import moment from 'moment-timezone';
import * as _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Footer from 'components/general/Footer';
import { toast } from 'react-toastify';
import { DOWNLOAD_SAP_INVOICES_WITH_GST, FETCH_SAP_INVOICES_WITH_GST, GENERATE_SAP_INVOICES_WITH_GST, TRIGGER_LOGITAX_OCPI_INVOICES } from 'actions/sap';
import fileDownload from 'js-file-download';


const OcpiInvoicing = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const profileData = useSelector((state) => state.profile.userProfile);
    const currentUserName = _.get(profileData, 'name', '');

    const reportList = useSelector((state) => state.sapData.sapFetchedData);

    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth()); // 0 = Jan
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

    const [selectedInvoiceIds, setSelectedInvoiceIds] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    const generateSapInvoicesWithGst = (data) => {
        const payload = {
            ...data,
            month: moment({ year: selectedYear, month: selectedMonth }).format('YYYY-MM'),
        };

        const isExcel = payload.excel;
        const actionType = isExcel
            ? DOWNLOAD_SAP_INVOICES_WITH_GST
            : GENERATE_SAP_INVOICES_WITH_GST;

        dispatch({
            type: actionType.REQUEST,
            payload,
            cb: (res) => {
                if (res?.status === 200) {
                    if (isExcel && res.data) {
                        fileDownload(res.data, `OCPI Invoice.xlsx`);
                        toast.success('Excel downloaded successfully');
                    } else {
                        toast.success('Invoices Generated Successfully');
                    }
                }
            },
        });
    };

    const fetchInvoices = () => {
        const monthString = moment({ year: selectedYear, month: selectedMonth }).format('YYYY-MM');
        dispatch({
            type: FETCH_SAP_INVOICES_WITH_GST.REQUEST,
            payload: { month: monthString },
        });
    };


    const handleCheckboxChange = (invoiceId) => {
        setSelectedInvoiceIds((prev) =>
            prev.includes(invoiceId)
                ? prev.filter((id) => id !== invoiceId)
                : [...prev, invoiceId]
        );
    };

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedInvoiceIds([]);
            setSelectAll(false);
        } else {
            const allIds = reportList.map((item) => item._id);
            setSelectedInvoiceIds(allIds);
            setSelectAll(true);
        }
    };

    const triggerLogitaxOcpiInvoices = () => {
        const payload = {
            month: moment({ year: selectedYear, month: selectedMonth }).format('YYYY-MM'),
            invoiceIds: selectedInvoiceIds,
        };

        dispatch({
            type: TRIGGER_LOGITAX_OCPI_INVOICES.REQUEST,
            payload,
            cb: (res) => {
                if (res?.status === 200) {
                    toast.success("Logitax Invoices Triggered Successfully");
                    fetchInvoices({});
                }
            },
        });
    };

    useEffect(() => {
        dispatch({
            type: FETCH_SAP_INVOICES_WITH_GST.SUCCESS,
            payload: [],
        });
        setSelectedInvoiceIds([]);
        setSelectAll(false);
    }, [selectedMonth, selectedYear]);

    return (
        <>
            <LayoutContainer>
                <AdminHeader title={t('sap.ocpiInvoicing')} />
                <div className="page-content-wrapper scrollable">
                    <div className="data-report--main">
                        <Card>
                            <div className="data-report__inner">
                                <div className="data-report__box">
                                    <Row className="data-report__row">

                                        <Col lg={2}>
                                            <select
                                                className="form-control"
                                                value={selectedYear}
                                                onChange={(e) => setSelectedYear(Number(e.target.value))}
                                            >
                                                {Array.from({ length: 5 }, (_, i) => {
                                                    const year = new Date().getFullYear() - 4 + i;
                                                    return (
                                                        <option key={year} value={year}>
                                                            {year}
                                                        </option>
                                                    );
                                                })}
                                            </select>

                                        </Col>


                                        <Col lg={2}>
                                            <select
                                                className="form-control"
                                                value={selectedMonth}
                                                onChange={(e) => setSelectedMonth(Number(e.target.value))}
                                            >
                                                {Array.from({ length: 12 }, (_, i) => {
                                                    const isCurrentYear = selectedYear === new Date().getFullYear();
                                                    const isMonthAllowed = isCurrentYear ? i < new Date().getMonth() : true;
                                                    return isMonthAllowed ? (
                                                        <option key={i} value={i}>
                                                            {moment().month(i).format('MMMM')}
                                                        </option>
                                                    ) : null;
                                                })}
                                            </select>

                                        </Col>

                                        <Col lg={'auto'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
                                            <Button
                                                type="submit"
                                                title="Generate Invoices"
                                                className="apply--btn"
                                                onClick={() => generateSapInvoicesWithGst({})}
                                            >
                                                {t('Generate Data')}
                                            </Button>
                                            <Button
                                                variant="info"
                                                title="Show Data"
                                                className="apply--btn"
                                                onClick={fetchInvoices}
                                            >
                                                Show Data
                                            </Button>
                                            <Button
                                                variant="success"
                                                title="Download Excel"
                                                className="apply--btn"
                                                onClick={() =>
                                                    generateSapInvoicesWithGst({
                                                        excel: true,
                                                        report: 'invoiceOCPI',
                                                    })
                                                }
                                            >
                                                {t('Download Excel')}
                                            </Button>
                                        </Col>

                                    </Row>
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className="data-table-wrapper mt-4">
                        <table className="record-list-table" id="table-to-xls">
                            <thead>
                                <tr>
                                    <th>{t('sap.srNo')}</th>
                                    <th>{t('sap.partnerId')}</th>
                                    <th>{t('sap.state')}</th>
                                    <th>{t('sap.sapCustomerId')}</th>
                                    <th>{t('sap.taxableAmount')}</th>
                                    <th>{t('sap.totalGst')}</th>
                                    <th>{t('sap.gstType')}</th>
                                    <th>{t('sap.totalRevenue')}</th>
                                    <th>{t('sap.averageTariff')}</th>
                                    <th>{t('sap.ocpiGst')}</th>
                                    <th>{t('sap.czGst')}</th>
                                    <th>Logitax Invoice</th>
                                    <th>Download Invoice</th>
                                </tr>
                            </thead>
                            <tbody>
                                {reportList && reportList.length > 0 ? (
                                    reportList.map((item, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{_.get(item, 'party_id', '-')}</td>
                                            <td>{_.get(item, 'state', '-')}</td>
                                            <td>{_.get(item, 'sap_customer_id', '-')}</td>
                                            <td>{_.get(item, 'total_taxable_amount', 0).toFixed(2)}</td>
                                            <td>{_.get(item, 'total_gst', 0).toFixed(2)}</td>
                                            <td>{_.get(item, 'gst_type', '-')}</td>
                                            <td>{_.get(item, 'total_revenue', 0).toFixed(2)}</td>
                                            <td>{_.get(item, 'average_tariff', 0).toFixed(2)}</td>
                                            <td>{_.get(item, 'ocpi_gst', '-')}</td>
                                            <td>{_.get(item, 'cz_gst', '-')}</td>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    checked={
                                                        selectedInvoiceIds.includes(item._id) || item.sendThroughLogitax === true
                                                    }
                                                    disabled={item.sendThroughLogitax === true}
                                                    onChange={() => handleCheckboxChange(item._id)}
                                                />
                                            </td>
                                            <td>
                                                {
                                                    item?.sendThroughLogitax === true ?
                                                        < Button
                                                            type="submit"
                                                            title="Download"
                                                            className="apply--btn"
                                                            onClick={() => window.open(item?.logitax_response[0].PDFEInvurl, '_blank')}
                                                        >
                                                            {t('Download')}
                                                        </Button>
                                                        :
                                                        ''
                                                }
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="5" className="text-center">
                                            {t('All Invoices Will be here')}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className="mt-3 d-flex gap-3 align-items-center">
                        <Button variant="secondary" onClick={handleSelectAll}>
                            {selectAll ? 'Unselect All' : 'Select All'}
                        </Button>

                        <Button
                            variant="warning"
                            disabled={selectedInvoiceIds.length === 0}
                            onClick={triggerLogitaxOcpiInvoices}
                        >
                            Trigger Logitax Invoices
                        </Button>
                    </div>
                    {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
                </div >
            </LayoutContainer >
        </>
    );
};

export default OcpiInvoicing;