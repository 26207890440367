import React, { useCallback, useEffect, useState, useContext } from 'react';
import Card from 'components/inputs/Card';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header/index';
import { Row, Col } from 'react-bootstrap';
import { Formik, Form, FieldArray, getIn } from 'formik';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import _ from 'lodash';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { CREATE_OEM, GET_OEM, UPDATE_OEM } from 'actions/oem';
import Select from 'components/inputs/Select';
import SessionHeading from 'components/general/SessionHeading';
import { BiPlus, BiTrash } from 'react-icons/bi';
import { FETCH_CONNECTOR_TYPE } from 'actions/connectorType';
import { FETCH_CHARGE_SPEED } from 'actions/chargeSpeed';
import { useParams } from 'react-router-dom';
import { FETCH_OEM_VENDOR } from 'actions/oemVendor';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import EditOemModal from './EditOemModal';
import { FETCH_CHARGER } from 'actions/charger';
import { useTranslation } from 'react-i18next';
import { NavContext } from 'components/privateRoute/PrivateRoute';
import CheckBox from 'components/inputs/CheckBox';

const OemForm = () => {
  const { oemId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // const history = useHistory();
  const { navigateTo } = useContext(NavContext);

  const oemDetail = useSelector((state) => state.oem.oemDetail);
  const allOemVendor = useSelector((state) => state.oemVendor.oemVendors);
  const oemPage = useSelector((state) => state.oemVendor.page);
  const connectorTypePage = useSelector((state) => state.connectorType.page);
  const chargeSpeedPage = useSelector((state) => state.chargeSpeed.page);
  const oemTotalPage = useSelector((state) => state.oemVendor.totalPages);
  const connectorTypeTotalPage = useSelector((state) => state.connectorType.totalPages);
  const chargeSpeedTotalPage = useSelector((state) => state.chargeSpeed.totalPages);
  const allConnectorType = useSelector((state) => state.connectorType.connectorTypes);
  const allChargeSpeed = useSelector((state) => state.chargeSpeed.chargeSpeed);
  const isLoading = useSelector((state) => state.oem.isLoading);
  const chargerData = useSelector((state) => state.charger.chargers);
  const [showEditOemModal, setShowEditOemModal] = useState(false);
  const [editData, setEditData] = useState();
  const [oemVendor, setOemVendor] = useState();

  const chargerName = _.join(
    _.map(chargerData, (item) => item.charger_id),
    ' , '
  );

  const addOem = useCallback((data) => {
    dispatch({ type: CREATE_OEM.REQUEST, payload: data });
  }, []);

  const getOemDetail = useCallback((id) => {
    dispatch({ type: GET_OEM.REQUEST, payload: id });
  }, []);

  const getChargerId = useCallback(() => {
    const oemData = {
      oem: oemId,
    };
    dispatch({ type: FETCH_CHARGER.REQUEST, payload: oemData });
  }, []);

  const updateOem = useCallback((data) => {
    dispatch({ type: UPDATE_OEM.REQUEST, payload: data });
  }, []);

  const getOemVendor = useCallback((data = {}) => {
    dispatch({ type: FETCH_OEM_VENDOR.REQUEST, payload: data });
  }, []);

  const getConnectorType = useCallback((data = {}) => {
    dispatch({ type: FETCH_CONNECTOR_TYPE.REQUEST, payload: data });
  }, []);

  const getChargeSpeed = useCallback((data = {}) => {
    dispatch({ type: FETCH_CHARGE_SPEED.REQUEST, payload: data });
  }, []);

  useEffect(() => {
    getOemVendor({ limit: 999 });
    getConnectorType();
    getChargeSpeed();
  }, []);

  useEffect(() => {
    if (oemId) {
      getOemDetail(oemId);
      getChargerId(oemId);
    }
  }, [oemId]);

  const handleCloseEditOemModel = () => {
    setShowEditOemModal(false);
  };

  const handleClick = () => {
    updateOem(editData);
    setShowEditOemModal(false);
  };

  const OemSchema = Yup.object().shape({
    vendor: Yup.string().required(t('oemForm.vendor')),
    name: Yup.string().required(t('oemForm.name')).strict(true).trim(t('oemForm.space')).max(100, t('oemForm.maxlimit')),
    input_voltage: Yup.string().required(t('oemForm.inputVoltage')).strict(true).trim(t('oemForm.space')).max(100, t('oemForm.maxlimit')),
    charger_capacity: Yup.string().required(t('oemForm.charger_capacity')),
    power_factor: Yup.string().required(t('oemForm.powerFactor')).strict(true).trim(t('oemForm.space')).max(100, t('oemForm.maxlimit')),
    efficiency: Yup.string().required(t('oemForm.efficiency')).strict(true).trim(t('oemForm.space')).max(100, t('oemForm.maxlimit')),
    input_frequency: Yup.string().required(t('oemForm.inputFrequency')).strict(true).trim(t('oemForm.space')).max(100, t('oemForm.maxlimit')),
    display: Yup.string().required(t('oemForm.display')).strict(true).trim(t('oemForm.space')).max(100, t('oemForm.maxlimit')),
    push_button: Yup.string().required(t('oemForm.pushButton')).strict(true).trim(t('oemForm.space')).max(100, t('oemForm.maxlimit')),
    dimension_w_h_d: Yup.string().required(t('oemForm.dimensionWHD')).strict(true).trim(t('oemForm.space')).max(100, t('oemForm.maxlimit')),
    plugs: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(t('oemForm.plug')).strict(true).trim(t('oemForm.space')),
        type: Yup.string().required(t('oemForm.type')).strict(true).trim(t('oemForm.space')),
        connector_type: Yup.string()
          .required(t('oemForm.connectorType'))
          .typeError(t('oemForm.connectorTypeError'))
          .strict(true)
          .trim(t('oemForm.space')),
        charge_speed: Yup.string().required(t('oemForm.chargeSpeed')).typeError(t('oemForm.chargeSpeedError')).strict(true).trim(t('oemForm.space')),
        code: Yup.string().required(t('oemForm.code')).strict(true).trim(t('oemForm.space')),
        current: Yup.string().required(t('oemForm.current')).strict(true).trim(t('oemForm.space')),
        rating: Yup.string().required(t('oemForm.rating')).strict(true).trim(t('oemForm.space')),
        number: Yup.number().required(t('oemForm.number')).typeError(t('oemForm.numTypeError')).positive(t('oemForm.positive')),
        format: Yup.string().required(t('oemForm.format')).strict(true).trim(t('oemForm.space')),
        max_voltage: Yup.string().required(t('oemForm.maxVoltage')).strict(true).trim(t('oemForm.space')),
        power_type: Yup.string().required(t('oemForm.powerType')).strict(true).trim(t('oemForm.space')),
      })
    ),
  });

  const initialValues = oemId
    ? {
        ...oemDetail,
        vendor: _.get(oemDetail, 'vendor.id', ''),
        plugs: _.map(oemDetail.plugs, (item) => {
          return { ...item, charge_speed: item.charge_speed.id, connector_type: item.connector_type.id };
        }),
      }
    : {
        name: '',
        vendor: '',
        input_voltage: '',
        charger_capacity: '',
        power_factor: '',
        efficiency: '',
        input_frequency: '',
        wires: '',
        ambient_temperature: '',
        storage_temperature: '',
        altitude: '',
        humidity: '',
        display: '',
        language: '',
        push_button: '',
        user_authentication: '',
        visual_indication: '',
        protection: '',
        charger_vehicle_communication: '',
        charger_cms_communication: '',
        ingress_protection: '',
        enclosure_protection: '',
        cooling: '',
        wire_length: '',
        dimension_w_h_d: '',
        image: 'https://imagepath.png',
        plugs: [
          {
            type: '',
            connector_type: '',
            charge_speed: '',
            name: '',
            code: '',
            current: '',
            rating: '',
            number: '',
            format: '',
            power_type: '',
            max_voltage: '',
          },
        ],
        is_auto_stop_enabled: false,
      };

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title={`${oemId ? 'Edit' : 'Add'} ChargeSpot Model`} backTo="/ChargeSpot" />
        <div className="page-content-wrapper scrollable">
          <div className="add-oem-page__main">
            <Card className="oem-form--card">
              <Formik
                enableReinitialize={!!oemId}
                initialValues={initialValues}
                validationSchema={OemSchema}
                onSubmit={(values, { setSubmitting }) => {
                  let uniqCode = _.uniqBy(values.plugs, 'code');
                  let uniqNumber = _.uniqBy(values.plugs, 'number');
                  if (uniqNumber.length === values.plugs.length) {
                    if (uniqCode.length === values.plugs.length) {
                      if (oemId) {
                        const updatedValues = { ...values, charger_capacity: String(values.charger_capacity) };
                        const updatedOemData = _.omit(updatedValues, ['make']);
                        const oemData = { ...updatedOemData, plugs: _.map(values.plugs, (item) => _.omit(item, ['_id'])) };
                        !_.isEmpty(chargerData) ? setShowEditOemModal(true) : updateOem(oemData);
                        setEditData(oemData);
                      } else {
                        const newValues = { ...values, charger_capacity: String(values.charger_capacity) };
                        addOem(newValues);
                      }
                    } else {
                      toast.error('Plugs code field must be uniq');
                    }
                  } else {
                    toast.error('Plugs number field must be uniq');
                  }
                  setSubmitting(false);
                }}
              >
                {({ values, errors, touched, handleSubmit, setFieldValue, handleChange, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <SessionHeading text="Basic" />
                    <div className="oem-form--block">
                      <Row>
                        <Col lg={6} md={12}>
                          <Select
                            isRequired
                            label={t('oemForm.oemName')}
                            options={_.map(allOemVendor, (item) => {
                              return { label: item.name, value: item.id };
                            })}
                            placeholder={t('placeHolder.selectOEMName')}
                            name="vendor"
                            value={_.get(values, 'vendor', '')}
                            onMenuScrollDown={true}
                            getDataOnScrollDown={getOemVendor}
                            page={oemPage}
                            totalPage={oemTotalPage}
                            onChange={(val) => {
                              const vendor = allOemVendor.filter((oem) => oem.id === val)[0].name;
                              setOemVendor(vendor);
                              setFieldValue(`vendor`, val);
                              if (vendor !== 'EXICOM') {
                                setFieldValue(`is_auto_stop_enabled`, false);
                              } else if (oemDetail.is_auto_stop_enabled) {
                                setFieldValue(`is_auto_stop_enabled`, oemDetail.is_auto_stop_enabled);
                              }
                            }}
                            error={getIn(errors, `vendor`) && getIn(touched, `vendor`) ? getIn(errors, `vendor`) : null}
                          />
                        </Col>
                        <Col lg={6} md={12}>
                          <TextInput
                            isRequired
                            label={t('oemForm.chargeSpotModelName')}
                            placeholder={t('oemForm.chargeSpotModelName')}
                            name="name"
                            value={_.get(values, 'name', '')}
                            onChange={handleChange}
                            error={errors.name && touched.name ? errors.name : null}
                          />
                        </Col>
                        <Col lg={6} md={12}>
                          <TextInput
                            label={t('oemForm.visualIndication')}
                            placeholder={t('oemForm.visualIndication')}
                            name="visual_indication"
                            value={_.get(values, 'visual_indication', '')}
                            onChange={handleChange}
                          />
                        </Col>
                        <Col lg={6} md={12}>
                          <TextInput
                            label={t('oemForm.protection')}
                            placeholder={t('oemForm.protection')}
                            name="protection"
                            value={_.get(values, 'protection', '')}
                            onChange={handleChange}
                          />
                        </Col>
                        <Col lg={6} md={12}>
                          <TextInput
                            isRequired
                            post_text="kW"
                            type="number"
                            label={t('oemForm.chargerCapacity')}
                            placeholder={t('oemForm.chargerCapacity')}
                            name="charger_capacity"
                            value={parseFloat(_.get(values, 'charger_capacity', ''))}
                            onChange={handleChange}
                            error={errors.charger_capacity && touched.charger_capacity ? errors.charger_capacity : null}
                          />
                        </Col>
                      </Row>
                      <SessionHeading text={t('oemForm.inputPower')} />
                      <Row>
                        <Col lg={6} md={12}>
                          <TextInput
                            isRequired
                            label={t('oemForm.inputVoltageLabel')}
                            placeholder={t('oemForm.inputVoltageLabel')}
                            name="input_voltage"
                            value={_.get(values, 'input_voltage', '')}
                            onChange={handleChange}
                            error={errors.input_voltage && touched.input_voltage ? errors.input_voltage : null}
                          />
                        </Col>
                        <Col lg={6} md={12}>
                          <TextInput
                            isRequired
                            label={t('oemForm.powerFactorLabel')}
                            placeholder={t('oemForm.powerFactorLabel')}
                            name="power_factor"
                            value={_.get(values, 'power_factor', '')}
                            onChange={handleChange}
                            error={errors.power_factor && touched.power_factor ? errors.power_factor : null}
                          />
                        </Col>
                        <Col lg={6} md={12}>
                          <TextInput
                            isRequired
                            label={t('oemForm.efficiencyLabel')}
                            placeholder={t('oemForm.efficiencyLabel')}
                            name="efficiency"
                            value={_.get(values, 'efficiency', '')}
                            onChange={handleChange}
                            error={errors.efficiency && touched.efficiency ? errors.efficiency : null}
                          />
                        </Col>
                        <Col lg={6} md={12}>
                          <TextInput
                            isRequired
                            label={t('oemForm.inputFrequencyLabel')}
                            placeholder={t('oemForm.inputFrequencyLabel')}
                            name="input_frequency"
                            value={_.get(values, 'input_frequency', '')}
                            onChange={handleChange}
                            error={errors.input_frequency && touched.input_frequency ? errors.input_frequency : null}
                          />
                        </Col>
                        <Col lg={6} md={12}>
                          <TextInput
                            label={t('oemForm.wires')}
                            placeholder={t('oemForm.wires')}
                            name="wires"
                            value={_.get(values, 'wires', '')}
                            onChange={handleChange}
                          />
                        </Col>
                      </Row>
                      <SessionHeading text="Environment" />
                      <Row>
                        <Col lg={6} md={12}>
                          <TextInput
                            label={t('oemForm.ambientTemperature')}
                            placeholder={t('oemForm.ambientTemperature')}
                            name="ambient_temperature"
                            value={_.get(values, 'ambient_temperature', '')}
                            onChange={handleChange}
                          />
                        </Col>
                        <Col lg={6} md={12}>
                          <TextInput
                            label={t('oemForm.storageTemperature')}
                            placeholder={t('oemForm.storageTemperature')}
                            // type="number"
                            name="storage_temperature"
                            value={_.get(values, 'storage_temperature', '')}
                            onChange={handleChange}
                          />
                        </Col>
                        <Col lg={6} md={12}>
                          <TextInput
                            label={t('oemForm.altitude')}
                            placeholder={t('oemForm.altitude')}
                            // type="number"
                            name="altitude"
                            value={_.get(values, 'altitude', '')}
                            onChange={handleChange}
                          />
                        </Col>
                        <Col lg={6} md={12}>
                          <TextInput
                            label={t('oemForm.humidity')}
                            placeholder={t('oemForm.humidity')}
                            // type="number"
                            name="humidity"
                            value={_.get(values, 'humidity', '')}
                            onChange={handleChange}
                          />
                        </Col>
                      </Row>
                      <SessionHeading text={t('oemForm.userInteraction')} />
                      <Row>
                        <Col lg={6} md={12}>
                          <TextInput
                            isRequired
                            label={t('oemForm.displayLabel')}
                            placeholder={t('oemForm.displayLabel')}
                            name="display"
                            value={_.get(values, 'display', '')}
                            onChange={handleChange}
                            error={errors.display && touched.display ? errors.display : null}
                          />
                        </Col>
                        <Col lg={6} md={12}>
                          <TextInput
                            label={t('oemForm.language')}
                            placeholder={t('oemForm.language')}
                            name="language"
                            value={_.get(values, 'language', '')}
                            onChange={handleChange}
                          />
                        </Col>
                        <Col lg={6} md={12}>
                          <TextInput
                            isRequired
                            label={t('oemForm.pushButtonLabel')}
                            placeholder={t('oemForm.pushButtonLabel')}
                            name="push_button"
                            value={_.get(values, 'push_button', '')}
                            onChange={handleChange}
                            error={errors.push_button && touched.push_button ? errors.push_button : null}
                          />
                        </Col>
                        <Col lg={6} md={12}>
                          <TextInput
                            label={t('oemForm.userAuthentication')}
                            placeholder={t('oemForm.userAuthentication')}
                            name="user_authentication"
                            value={_.get(values, 'user_authentication', '')}
                            onChange={handleChange}
                          />
                        </Col>
                        <Col lg={3} md={6}>
                          <label htmlFor="auto_stop_enabled" className="is-auto-stop-enabled--label">
                            Auto Stop Enabled
                          </label>
                          <CheckBox
                            disabled={oemVendor ? oemVendor !== 'EXICOM' : oemDetail ? oemDetail?.vendor?.name !== 'EXICOM' : false}
                            name="auto_stop_enabled"
                            checked={_.get(values, 'is_auto_stop_enabled', false)}
                            onChange={(e) => setFieldValue('is_auto_stop_enabled', e.target.checked)}
                          />
                        </Col>
                      </Row>
                      <SessionHeading text={t('oemForm.communication')} />
                      <Row>
                        <Col lg={6} md={12}>
                          <TextInput
                            label={t('oemForm.chargerVehicleCommunication')}
                            placeholder={t('oemForm.chargerVehicleCommunication')}
                            name="charger_vehicle_communication"
                            value={_.get(values, 'charger_vehicle_communication', '')}
                            onChange={handleChange}
                          />
                        </Col>
                        <Col lg={6} md={12}>
                          <TextInput
                            label={t('oemForm.chargerCmsCommunication')}
                            placeholder={t('oemForm.chargerCmsCommunication')}
                            name="charger_cms_communication"
                            value={_.get(values, 'charger_cms_communication', '')}
                            onChange={handleChange}
                          />
                        </Col>
                      </Row>
                      <SessionHeading text="Mechanical" />
                      <Row>
                        <Col lg={6} md={12}>
                          <TextInput
                            label={t('oemForm.ingressProtection')}
                            placeholder={t('oemForm.ingressProtection')}
                            name="ingress_protection"
                            value={_.get(values, 'ingress_protection', '')}
                            onChange={handleChange}
                          />
                        </Col>
                        <Col lg={6} md={12}>
                          <TextInput
                            label={t('oemForm.enclosureProtection')}
                            placeholder={t('oemForm.enclosureProtection')}
                            name="enclosure_protection"
                            value={_.get(values, 'enclosure_protection', '')}
                            onChange={handleChange}
                          />
                        </Col>
                        <Col lg={6} md={12}>
                          <TextInput
                            label={t('oemForm.cooling')}
                            placeholder={t('oemForm.cooling')}
                            name="cooling"
                            value={_.get(values, 'cooling', '')}
                            onChange={handleChange}
                          />
                        </Col>
                        <Col lg={6} md={12}>
                          <TextInput
                            label={t('oemForm.wireLength')}
                            placeholder={t('oemForm.wireLength')}
                            name="wire_length"
                            value={_.get(values, 'wire_length', '')}
                            onChange={handleChange}
                          />
                        </Col>
                        <Col lg={6} md={12}>
                          <TextInput
                            isRequired
                            label={t('oemForm.dimension')}
                            placeholder={t('oemForm.dimension')}
                            name="dimension_w_h_d"
                            value={_.get(values, 'dimension_w_h_d', '')}
                            onChange={handleChange}
                            error={errors.dimension_w_h_d && touched.dimension_w_h_d ? errors.dimension_w_h_d : null}
                          />
                        </Col>
                      </Row>
                    </div>
                    <SessionHeading text={t('oemForm.plugInformation')} />
                    <div className="oem-form--block">
                      <div className="plug-block__field-inputs">
                        <FieldArray
                          name="plugs"
                          render={(arrayHelpers) => (
                            <>
                              {_.map(values.plugs, (field, index) => (
                                <div key={`oem-plug-${index}`} className="oem-plug--item">
                                  <Row xxl={6} lg={4} md={4}>
                                    <Col>
                                      <Select
                                        isRequired
                                        label={t('filters.type')}
                                        options={[
                                          { label: 'AC', value: 'AC' },
                                          { label: 'DC', value: 'DC' },
                                        ]}
                                        placeholder={t('placeHolder.selectType')}
                                        name="type"
                                        value={field.type || ''}
                                        onChange={(e) => {
                                          setFieldValue(`plugs[${index}].type`, e);
                                          setFieldValue(`plugs[${index}].connector_type`, '');
                                        }}
                                        error={
                                          getIn(errors, `plugs[${index}].type`) && getIn(touched, `plugs[${index}].type`)
                                            ? getIn(errors, `plugs[${index}].type`)
                                            : null
                                        }
                                      />
                                    </Col>
                                    <Col>
                                      <Select
                                        isRequired
                                        label={t('filters.connectorType')}
                                        options={_.map(_.filter(allConnectorType, { connector_mode: field.type }), (item) => {
                                          return {
                                            label: item.name,
                                            value: item.id,
                                          };
                                        })}
                                        placeholder={t('placeHolder.selectConnectorType')}
                                        name="connector_type"
                                        value={field.connector_type || ''}
                                        onMenuScrollDown={true}
                                        getDataOnScrollDown={getConnectorType}
                                        page={connectorTypePage}
                                        totalPage={connectorTypeTotalPage}
                                        onChange={(e) => setFieldValue(`plugs[${index}].connector_type`, e)}
                                        error={
                                          getIn(errors, `plugs[${index}].connector_type`) && getIn(touched, `plugs[${index}].connector_type`)
                                            ? getIn(errors, `plugs[${index}].connector_type`)
                                            : null
                                        }
                                      />
                                    </Col>
                                    <Col>
                                      <Select
                                        isRequired
                                        label={t('filters.chargerSpeed')}
                                        options={_.map(allChargeSpeed, (item) => {
                                          return { label: item.name, value: item.id };
                                        })}
                                        placeholder={t('placeHolder.selectChargerSpeed')}
                                        name="charge_speed"
                                        value={field.charge_speed || ''}
                                        onMenuScrollDown={true}
                                        getDataOnScrollDown={getChargeSpeed}
                                        page={chargeSpeedPage}
                                        totalPage={chargeSpeedTotalPage}
                                        onChange={(e) => setFieldValue(`plugs[${index}].charge_speed`, e)}
                                        error={
                                          getIn(errors, `plugs[${index}].charge_speed`) && getIn(touched, `plugs[${index}].charge_speed`)
                                            ? getIn(errors, `plugs[${index}].charge_speed`)
                                            : null
                                        }
                                      />
                                    </Col>
                                    <Col>
                                      <TextInput
                                        isRequired
                                        label={t('oemForm.nameLabel')}
                                        name="name"
                                        placeholder={t('oemForm.nameLabel')}
                                        value={field.name || ''}
                                        onChange={(e) => setFieldValue(`plugs[${index}].name`, e.target.value)}
                                        error={
                                          getIn(errors, `plugs[${index}].name`) && getIn(touched, `plugs[${index}].name`)
                                            ? getIn(errors, `plugs[${index}].name`)
                                            : null
                                        }
                                      />
                                    </Col>
                                    <Col>
                                      <TextInput
                                        isRequired
                                        label={t('oemForm.codeLabel')}
                                        name="code"
                                        placeholder={t('oemForm.codeLabel')}
                                        value={field.code || ''}
                                        onChange={(e) => setFieldValue(`plugs[${index}].code`, e.target.value)}
                                        error={
                                          getIn(errors, `plugs[${index}].code`) && getIn(touched, `plugs[${index}].code`)
                                            ? getIn(errors, `plugs[${index}].code`)
                                            : null
                                        }
                                      />
                                    </Col>
                                    <Col>
                                      <TextInput
                                        isRequired
                                        label={t('oemForm.currentLabel')}
                                        name="current"
                                        placeholder={t('oemForm.currentLabel')}
                                        value={field.current || ''}
                                        onChange={(e) => setFieldValue(`plugs[${index}].current`, e.target.value)}
                                        error={
                                          getIn(errors, `plugs[${index}].current`) && getIn(touched, `plugs[${index}].connector_type`)
                                            ? getIn(errors, `plugs[${index}].current`)
                                            : null
                                        }
                                      />
                                    </Col>
                                    <Col>
                                      <TextInput
                                        isRequired
                                        label={t('oemForm.ratingLabel')}
                                        name="rating"
                                        placeholder={t('oemForm.ratingLabel')}
                                        value={field.rating || ''}
                                        onChange={(e) => setFieldValue(`plugs[${index}].rating`, e.target.value)}
                                        error={
                                          getIn(errors, `plugs[${index}].rating`) && getIn(touched, `plugs[${index}].rating`)
                                            ? getIn(errors, `plugs[${index}].rating`)
                                            : null
                                        }
                                      />
                                    </Col>
                                    <Col>
                                      <TextInput
                                        isRequired
                                        label={t('oemForm.numberLabel')}
                                        type="number"
                                        name="number"
                                        placeholder={t('oemForm.numberLabel')}
                                        value={field.number || ''}
                                        onChange={(e) => setFieldValue(`plugs[${index}].number`, e.target.value)}
                                        error={
                                          getIn(errors, `plugs[${index}].number`) && getIn(touched, `plugs[${index}].number`)
                                            ? getIn(errors, `plugs[${index}].number`)
                                            : null
                                        }
                                      />
                                    </Col>
                                    <Col>
                                      <TextInput
                                        isRequired
                                        label={t('oemForm.maxVoltageLabel')}
                                        type="max voltage"
                                        name="max voltage"
                                        placeholder={t('oemForm.maxVoltageLabel')}
                                        value={field.max_voltage || ''}
                                        onChange={(e) => setFieldValue(`plugs[${index}].max_voltage`, e.target.value)}
                                        error={
                                          getIn(errors, `plugs[${index}].max_voltage`) && getIn(touched, `plugs[${index}].max_voltage`)
                                            ? getIn(errors, `plugs[${index}].max_voltage`)
                                            : null
                                        }
                                      />
                                    </Col>
                                    <Col>
                                      <Select
                                        isRequired
                                        label={t('oemForm.formatLabel')}
                                        options={[
                                          { label: 'SOCKET', value: 'SOCKET' },
                                          { label: 'CABLE', value: 'CABLE' },
                                        ]}
                                        placeholder={t('placeHolder.selectFormatName')}
                                        name="format"
                                        value={field.format || ''}
                                        onChange={(e) => setFieldValue(`plugs[${index}].format`, e)}
                                        error={
                                          getIn(errors, `plugs[${index}].format`) && getIn(touched, `plugs[${index}].format`)
                                            ? getIn(errors, `plugs[${index}].format`)
                                            : null
                                        }
                                      />
                                    </Col>
                                    <Col>
                                      <Select
                                        isRequired
                                        label={t('oemForm.powerType')}
                                        options={[
                                          { label: 'AC_1_PHASE', value: 'AC_1_PHASE' },
                                          { label: 'AC_3_PHASE', value: 'AC_3_PHASE' },
                                          { label: 'DC', value: 'DC' },
                                        ]}
                                        placeholder={t('placeHolder.selectPowerType')}
                                        name="power_type"
                                        value={field.power_type || ''}
                                        onChange={(e) => setFieldValue(`plugs[${index}].power_type`, e)}
                                        error={
                                          getIn(errors, `plugs[${index}].power_type`) && getIn(touched, `plugs[${index}].power_type`)
                                            ? getIn(errors, `plugs[${index}].power_type`)
                                            : null
                                        }
                                      />
                                    </Col>
                                    {index > 0 && (
                                      <Col xl={1} lg={1} md={1} className="d-flex align-items-center">
                                        <Button className="field--btn" onClick={() => arrayHelpers.remove(index)}>
                                          <BiTrash size={26} />
                                        </Button>
                                      </Col>
                                    )}
                                  </Row>
                                </div>
                              ))}
                              <div>
                                {values.plugs.length < 4 && (
                                  <Button
                                    className="field--btn"
                                    onClick={() => {
                                      arrayHelpers.insert(values.plugs.length, {
                                        type: '',
                                        connector_type: '',
                                        charge_speed: '',
                                        name: '',
                                        code: '',
                                        current: '',
                                        rating: '',
                                        number: '',
                                        format: '',
                                        power_type: '',
                                      });
                                    }}
                                  >
                                    <BiPlus size={22} className="plus--icon" /> Add
                                  </Button>
                                )}
                              </div>
                            </>
                          )}
                        />
                      </div>
                    </div>
                    <div className="form_btn__block">
                      <Button type="submit" disabled={isSubmitting || isLoading} className="form__btn">
                        {oemId ? 'Edit' : 'Add'} {t('header.chargespotModel')}
                      </Button>
                      <span className="oem-cancel-btn--block">
                        <Button className="oem-cancel--btn" onClick={() => navigateTo(`/ChargeSpot`)}>
                          {t('button.cancel')}
                        </Button>
                      </span>
                    </div>
                  </Form>
                )}
              </Formik>
            </Card>
          </div>
          {showEditOemModal && (
            <Modal show={showEditOemModal} centered onHide={handleCloseEditOemModel}>
              <EditOemModal subTitle={chargerName} title="chargeSpot model" onClose={handleCloseEditOemModel} editChargeSpotModel={handleClick} />
            </Modal>
          )}
        </div>
      </LayoutContainer>
    </React.Fragment>
  );
};
export default OemForm;
