import React, { useEffect, useState } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
// import './table.css';
import moment from 'moment';
import Card from 'components/inputs/Card';
// import { BsDownload } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import DateTimePicker from 'components/inputs/DateTimePicker';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Footer from 'components/general/Footer';
import DropDown from 'components/inputs/DropDown';
import { AiFillCaretDown } from 'react-icons/ai';
import { getTimezoneFromCountryCode } from 'utils/timezone/timezoneUtils';
import { DOWNLOAD_ACCOUNTING_REPORTS, DOWNLOAD_MONTHLY_REPORTS, FETCH_ACCOUNTING_REPORTS, FETCH_MONTHLY_REPORTS } from 'actions/smcGateway';
import { toast } from 'react-toastify';
import { SiGoogledocs, SiGooglesheets } from 'react-icons/si';
import fileDownload from 'js-file-download';

const AccountingReports = () => {

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const isLoading = useSelector((state) => state.downloadingLoader.isLoading);
    const isLoader = useSelector((state) => state.capacityUtilisation.isLoading);
    const page = useSelector((state) => state.capacityUtilisation.page);
    const limit = useSelector((state) => state.capacityUtilisation.limit);
    const profileData = useSelector((state) => state.profile.userProfile);
    const currentUserName = _.get(profileData, 'name', '');
    const [report, setReport] = useState('Select_Voucher');
    const [gateway, setGateway] = useState(null);
    const [fieldValue, setFieldValue] = useState('Select Gateway');
    const countryTimezone = getTimezoneFromCountryCode(_.get(profileData, 'country_code', 'UTC'));
    const [startDate, setStartDate] = useState(moment(new Date()).tz(countryTimezone).startOf('day').utc());
    const [endDate, setEndDate] = useState(moment(new Date()).tz(countryTimezone).endOf('day').utc());
    const [reportType, setReportType] = useState('Basic Report'); // New dropdown state


    const reportList = useSelector((state) => state.smcReports.reports);

    const initialValues = {
        from: moment(startDate).tz(countryTimezone).utc(),
        to: moment(endDate).tz(countryTimezone).utc(),
        report: [report],
        gatewayId: gateway,
    };

    const filterData = [
        { label: 'INCOME VOUCHER', value: 'JV' },
        { label: 'BRV WALLET DEPOSIT', value: 'BRV' },
    ];


    const fetchAllVoucherList = (data, format = null) => {
        if (report === 'Select_Voucher' || (report === 'BRV' && fieldValue === 'Select Gateway')) {
            return toast.error("Select Voucher Type Properly");
        }
        if (moment(startDate).isAfter(moment(endDate))) {
            return toast.error("From date cannot be greater than To date");
        }

        const actionType = format
            ? (reportType === 'Monthly Report' ? DOWNLOAD_MONTHLY_REPORTS.REQUEST : DOWNLOAD_ACCOUNTING_REPORTS.REQUEST)
            : (reportType === 'Monthly Report' ? FETCH_MONTHLY_REPORTS.REQUEST : FETCH_ACCOUNTING_REPORTS.REQUEST);


        if (report === 'BRV') {
            const payload = {
                ...data,
                from: moment(startDate).utc().startOf('day'),
                to: moment(endDate).utc().endOf('day'),
                report: [report],
                gatewayId: gateway,
                ...(format === 'excel' && { excel: true }),
                ...(format === 'pdf' && { request_pdf: true }),
            };

            dispatch({
                type: actionType,
                payload,
                cb: (res) => {
                    if (_.get(res, 'status') === 200) {
                        if (payload.excel === true) {
                            fileDownload(res.data, `SMC ${report}-${reportType}.xlsx`);
                        } else if (payload.request_pdf === true) {
                            fileDownload(res.data, `SMC ${report}-${reportType} .pdf`);
                        }
                        toast.success(format ? `Report ${format.toUpperCase()} Downloaded Successfully` : "Report Fetched Successfully");
                    }
                },
            });
        }
        else {
            const payload = {
                ...data,
                from: moment(startDate).utc().startOf('day'),
                to: moment(endDate).utc().endOf('day'),
                report: [report],
                ...(format === 'excel' && { excel: true }),
                ...(format === 'pdf' && { request_pdf: true }),
            };

            dispatch({
                type: actionType,
                payload,
                cb: (res) => {
                    if (_.get(res, 'status') === 200) {
                        if (payload.excel === true) {
                            fileDownload(res.data, `SMC ${report}-${reportType}.xlsx`);
                        } else if (payload.request_pdf === true) {
                            fileDownload(res.data, `SMC ${report}-${reportType} .pdf`);
                        }
                        toast.success(format ? `Report ${format.toUpperCase()} Downloaded Successfully` : "Report Fetched Successfully");
                    }
                },
            });
        }
    };

    useEffect(() => {
        // Clear report list on page load
        dispatch({
            type: FETCH_ACCOUNTING_REPORTS.SUCCESS,
            payload: [],
        });

        dispatch({
            type: FETCH_MONTHLY_REPORTS.SUCCESS,
            payload: [],
        });

    }, [report, gateway, reportType]);

    return (
        <>
            <LayoutContainer>
                <AdminHeader title={t('accountingReports.header')} />
                <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
                    <div className="page-content-wrapper scrollable">
                        <div className="data-report--main">
                            <Card>
                                <div className="data-report__inner">
                                    <div className="data-report__box">
                                        <Row className="data-report__row">
                                            <Col xl={'auto'} md={'auto'}>
                                                <div className="data-report-date_picker">
                                                    <DateTimePicker
                                                        onChangeOfStartDate={(item) => setStartDate(item)}
                                                        onChangeOfEndDate={(item) => setEndDate(item)}
                                                        initialValueOfStartDate={moment().endOf('day')} // Yesterday as default start date
                                                        initialValueOfEndDate={moment().endOf('day')} // Yesterday as default end date
                                                        minDate={moment().add(1, 'days').startOf('day')} // Prevent selection of today
                                                        closeOnSelect={true}
                                                        timeFormat={false} // Disable time selection
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={'auto'}>
                                                <DropDown
                                                    popupClass="main-menu bottom scrollable"
                                                    trigger={
                                                        <>
                                                            <div className="job-voucher-filter-block">
                                                                <div className="job-voucher-filter-sec">
                                                                    <span className="job-voucher-filter-label">{_.startCase(initialValues.report)}</span>
                                                                </div>
                                                                <div className="job-voucher-arrow__icon">
                                                                    <AiFillCaretDown />
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                    triggerClass="customerBooking-filter-dropdown"
                                                >
                                                    {_.map(filterData, (item, index) => (
                                                        <ul key={`${index}`}>
                                                            <li
                                                                className="item"
                                                                onClick={() => {
                                                                    setReport(item.value);
                                                                }}
                                                            >
                                                                {_.startCase(item.label)}
                                                            </li>
                                                        </ul>
                                                    ))}
                                                </DropDown>
                                            </Col>
                                            {report === 'BRV' && (
                                                <Col lg={'auto'}>
                                                    <DropDown
                                                        popupClass="main-menu bottom scrollable"
                                                        trigger={
                                                            <>
                                                                <div className="job-voucher-filter-block">
                                                                    <div className="job-voucher-filter-sec">
                                                                        <span className="job-voucher-filter-label">{fieldValue}</span>
                                                                    </div>
                                                                    <div className="job-voucher-arrow__icon">
                                                                        <AiFillCaretDown />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }
                                                        triggerClass="customerBooking-filter-dropdown"
                                                    >
                                                        {[
                                                            { name: 'BillDesk (Kotak Bank)', id: 1 },
                                                            { name: 'ICICI BANK', id: 2 },
                                                            { name: 'HDFC BANK', id: 3 },
                                                            { name: 'All Bank Detail', id: null },
                                                        ].map((bank, index) => (
                                                            <ul key={`${index}`}>
                                                                <li
                                                                    className="item"
                                                                    onClick={() => {
                                                                        setFieldValue(bank.name);
                                                                        setGateway(bank.id); // Remove this line if 'All Bank Detail' shouldn't have gatewayId
                                                                    }}
                                                                >
                                                                    {bank.name}
                                                                </li>
                                                            </ul>
                                                        ))}
                                                    </DropDown>
                                                </Col>
                                            )}
                                            <Col lg={'auto'}>
                                                <DropDown
                                                    trigger={
                                                        <>
                                                            <div className="job-voucher-filter-block">
                                                                <div className="job-voucher-filter-sec">
                                                                    <span className="job-voucher-filter-label">{reportType}</span>
                                                                </div>
                                                                <div className="job-voucher-arrow__icon">
                                                                    <AiFillCaretDown />
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                    triggerClass="report-type-dropdown"
                                                    popupClass="main-menu bottom scrollable"
                                                >
                                                    {[
                                                        { label: 'Basic Report', value: 'Basic Report' },
                                                        { label: 'Monthly Report', value: 'Monthly Report' }
                                                    ].map((option, index) => (
                                                        <ul key={index}>
                                                            <li onClick={() => setReportType(option.value)}>{option.label}</li>
                                                        </ul>
                                                    ))}
                                                </DropDown>
                                            </Col>
                                            <Col xl={'auto'} md={'auto'} className="excel-icon--block" onClick={() => fetchAllVoucherList({})}>
                                                {isLoading ? (
                                                    <div className="loader--block">
                                                        <Spinner as="span" animation="border" size="sm" role="status" />
                                                    </div>
                                                ) : (
                                                    <div className="report-excel-icon">
                                                        <Button title="Show Report" size={35} color={'#3c7cdd'}>{t('Submit')}</Button>
                                                    </div>
                                                )}
                                            </Col>
                                            <Col xl={'auto'} md={'auto'} className="excel-icon--block" onClick={() => fetchAllVoucherList({}, 'excel')}>
                                                {isLoading ? (
                                                    <div className="loader--block">
                                                        <Spinner as="span" animation="border" size="sm" role="status" />
                                                    </div>
                                                ) : (
                                                    <div className="report-excel-icon">
                                                        <SiGooglesheets title="Download Excel Format" size={35} color={'#5dad5d'} />
                                                    </div>
                                                )}
                                            </Col>
                                            <Col xl={'auto'} md={'auto'} className="excel-icon--block" onClick={() => fetchAllVoucherList({}, 'pdf')}>
                                                {isLoading ? (
                                                    <div className="loader--block">
                                                        <Spinner as="span" animation="border" size="sm" role="status" />
                                                    </div>
                                                ) : (
                                                    <div className="report-excel-icon">
                                                        <SiGoogledocs title="Download PDF Format" size={35} color={'#3c7cdd'} />
                                                    </div>
                                                )}
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="data-report__table">
                                        <div className="table-responsive">
                                            <table className="record-list-table" id="table-to-xls">
                                                <thead>
                                                    <tr>


                                                        {(reportType === 'Basic Report' && report === 'BRV') && (
                                                            <>
                                                                <th>{isLoader ? <Skeleton height={15} width={100} /> : t('accountingReports.srNo')}</th>
                                                                <th>{isLoader ? <Skeleton height={15} width={100} /> : t('accountingReports.bankName')}</th>
                                                                <th>{isLoader ? <Skeleton height={15} width={100} /> : t('accountingReports.smcId')}</th>
                                                                <th>{isLoader ? <Skeleton height={15} width={100} /> : t('accountingReports.transactionDate')}</th>
                                                                <th>{isLoader ? <Skeleton height={15} width={100} /> : t('accountingReports.customerName')}</th>
                                                                <th>{isLoader ? <Skeleton height={15} width={100} /> : t('accountingReports.customerMobile')}</th>
                                                                <th>{isLoader ? <Skeleton height={15} width={100} /> : t('accountingReports.receiptAmount')}</th>
                                                                <th>{isLoader ? <Skeleton height={15} width={100} /> : t('accountingReports.voucherNumber')}</th>
                                                                <th>{isLoader ? <Skeleton height={15} width={100} /> : t('accountingReports.voucherDate')}</th>
                                                                <th>{isLoader ? <Skeleton height={15} width={100} /> : t('accountingReports.transactionId')}</th>
                                                                <th>{isLoader ? <Skeleton height={15} width={100} /> : t('accountingReports.voucherType')}</th>
                                                                <th>{isLoader ? <Skeleton height={15} width={100} /> : t('accountingReports.approvalDate')}</th>
                                                                <th>{isLoader ? <Skeleton height={15} width={100} /> : t('accountingReports.approvalName')}</th>
                                                            </>
                                                        )}

                                                        {(reportType === 'Basic Report' && report === 'JV') && (
                                                            <>
                                                                <th>{isLoader ? <Skeleton height={15} width={100} /> : t('accountingReports.srNo')}</th>
                                                                <th>{isLoader ? <Skeleton height={15} width={100} /> : t('accountingReports.transactionDate')}</th>
                                                                <th>{isLoader ? <Skeleton height={15} width={100} /> : t('accountingReports.customerName')}</th>
                                                                <th>{isLoader ? <Skeleton height={15} width={100} /> : t('accountingReports.customerMobile')}</th>
                                                                <th>{isLoader ? <Skeleton height={15} width={100} /> : t('accountingReports.customerGST')}</th>
                                                                <th>{isLoader ? <Skeleton height={15} width={100} /> : t('accountingReports.subtotal')}</th>
                                                                <th>{isLoader ? <Skeleton height={15} width={100} /> : t('accountingReports.sgst')}</th>
                                                                <th>{isLoader ? <Skeleton height={15} width={100} /> : t('accountingReports.cgst')}</th>
                                                                <th>{isLoader ? <Skeleton height={15} width={100} /> : t('accountingReports.amount')}</th>
                                                                <th>{isLoader ? <Skeleton height={15} width={100} /> : t('accountingReports.transactionId')}</th>
                                                                <th>{isLoader ? <Skeleton height={15} width={100} /> : t('accountingReports.voucherNumber')}</th>
                                                                <th>{isLoader ? <Skeleton height={15} width={100} /> : t('accountingReports.voucherDate')}</th>
                                                                <th>{isLoader ? <Skeleton height={15} width={100} /> : t('accountingReports.voucherType')}</th>
                                                                <th>{isLoader ? <Skeleton height={15} width={100} /> : t('accountingReports.approvalDate')}</th>
                                                                <th>{isLoader ? <Skeleton height={15} width={100} /> : t('accountingReports.approvalName')}</th>
                                                            </>
                                                        )}

                                                        {(reportType === 'Monthly Report' && report === 'BRV') && (
                                                            <>
                                                                <th>{isLoader ? <Skeleton height={15} width={100} /> : t('accountingReports.srNo')}</th>
                                                                <th>{isLoader ? <Skeleton height={15} width={100} /> : t('accountingReports.transactionDate')}</th>
                                                                <th>{isLoader ? <Skeleton height={15} width={100} /> : t('accountingReports.totalTransactions')}</th>
                                                                <th>{isLoader ? <Skeleton height={15} width={100} /> : t('accountingReports.totalAmount')}</th>
                                                            </>
                                                        )}

                                                        {(reportType === 'Monthly Report' && report === 'JV') && (
                                                            <>
                                                                <th>{isLoader ? <Skeleton height={15} width={100} /> : t('accountingReports.srNo')}</th>
                                                                <th>{isLoader ? <Skeleton height={15} width={100} /> : t('accountingReports.transactionDate')}</th>
                                                                <th>{isLoader ? <Skeleton height={15} width={100} /> : t('accountingReports.totalTransactions')}</th>
                                                                <th>{isLoader ? <Skeleton height={15} width={100} /> : t('accountingReports.totalAmount')}</th>
                                                            </>
                                                        )}
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {isLoader ? (
                                                        <tr>
                                                            <td colSpan={15} className="border-0">
                                                                <div className="loader--block">
                                                                    <Skeleton height={15} width={100} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ) : _.isEmpty(reportList) ? (
                                                        <tr>
                                                            <td colSpan={15} className="border-0">
                                                                <div className="empty-data-block">{t('accountingReports.noData')}</div>
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        _.map(reportList, (item, key) => {
                                                            const serial_num = limit * (page - 1) + key + 1;

                                                            return (
                                                                <tr key={`report-${item.transactionId}-${serial_num}`}>
                                                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : serial_num}</td>

                                                                    {(reportType === 'Basic Report' && report === 'BRV') && (
                                                                        <>
                                                                            <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'bankName', '-')}</td>
                                                                            <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'smcId', '-')}</td>
                                                                            <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'transactionDate', '-')}</td>
                                                                            <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'customerName', '-')}</td>
                                                                            <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'customerMobile', '-')}</td>
                                                                            <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'receiptAmount', '-')}</td>
                                                                            <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'voucherNumber', '-')}</td>
                                                                            <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'voucherDate', '-')}</td>
                                                                            <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'transactionId', '-')}</td>
                                                                            <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'voucherType', '-')}</td>
                                                                            <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'approvalDate', '-')}</td>
                                                                            <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'approvalName', '-')}</td>
                                                                        </>
                                                                    )}

                                                                    {(reportType === 'Basic Report' && report === 'JV') && (
                                                                        <>
                                                                            <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'transactionDate', '-')}</td>
                                                                            <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'customerName', '-')}</td>
                                                                            <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'customerMobile', '-')}</td>
                                                                            <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'customerGST', '-')}</td>
                                                                            <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'subtotal', '-')}</td>
                                                                            <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'sgst', '-')}</td>
                                                                            <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'cgst', '-')}</td>
                                                                            <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'amount', '-')}</td>
                                                                            <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'transactionId', '-')}</td>
                                                                            <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'voucherNumber', '-')}</td>
                                                                            <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'voucherDate', '-')}</td>
                                                                            <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'voucherType', '-')}</td>
                                                                            <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'approvalDate', '-')}</td>
                                                                            <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'approvalName', '-')}</td>
                                                                        </>
                                                                    )}

                                                                    {(reportType === 'Monthly Report' && report === 'BRV') && (
                                                                        <>
                                                                            <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'transactionDate', '-')}</td>
                                                                            <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'totalTransactions', '-')}</td>
                                                                            <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'totalAmount', '-')}</td>
                                                                        </>
                                                                    )}

                                                                    {(reportType === 'Monthly Report' && report === 'JV') && (
                                                                        <>
                                                                            <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'transactionDate', '-')}</td>
                                                                            <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'totalTransactions', '-')}</td>
                                                                            <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'totalAmount', '-')}</td>
                                                                        </>
                                                                    )}

                                                                </tr>
                                                            );
                                                        })
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>
                </SkeletonTheme>
                {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
            </LayoutContainer>
        </>
    );
};

export default AccountingReports;
