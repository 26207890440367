import React from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
import { Formik, Form } from 'formik';
import { Row, Col } from 'react-bootstrap';
import Button from 'components/inputs/Button';
import TextInput from 'components/inputs/Input';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_CPO } from 'actions/partnerManagement';
import { useTranslation } from 'react-i18next';
import Select from 'components/inputs/Select';
import * as Yup from 'yup';
import './Configuration.scss';
import { useParams } from 'react-router';
import ToggleSwitch from 'components/inputs/ToggleSwitch';
import { useNavigate } from 'react-router-dom';

const ocpiOptions = [{ value: "2.2", label: "2.2" }, { value: "2.2.1", label: "2.2.1" }];
const chargingTypeOptions = [{ value: "offline", label: "Offline" }, { value: "online", label: "Online" }, { value: "both", label: "Both" }];
const calculationOptions = [{ value: "fixed_amount", label: "Fixed Amount" }, { value: "percentage", label: "Percentage" }];


// App Configuration Checklist items
const appChecklistItems = [
  { key: 'is_session_push_supported', label: 'Session Push Supported', default: true, description: '(We, as an EMSP, push real-time SoC and kWh updates to the CZ App.)' },

];

//Core Functionality Checklist items
const coreChecklistItems = [
  { key: 'is_reserve_now_supported', label: 'Reserve Now Supported', default: false, description: '(If the CPO supports Reserve Now, we as an EMSP send a reservation request before starting a session.)' },
  { key: 'allow_credential_post_as_response', label: 'Allow Credential Post as Response', default: true, description: '(Allowing credential post as a response.)' },
  { key: 'is_limit_location_enable', label: 'Limit Location Enable', default: false, description: '(Restricting specific stations and chargers to certain clients)' },
  { key: 'is_connector_patch_supported', label: 'Connector Patch Supported', default: false, description: '(Sends connector-level status with an extra / for the connector ID.)' },
  { key: 'is_custom_business_details_supported', label: 'Custom Business Details Supported', default: false, description: '(Allows sharing customer helpline and business details during handshake or credential posting.)' },
  { key: 'is_fleet_details_supported', label: 'Fleet Details Supported', default: false, description: '(Expect fleet details from EMSP.)' },

];

//Finance Checklist items
const financeChecklistItems = [
  { key: 'is_ocpi_customer_supported', label: 'OCPI Customer Supported', default: false, description: '(EMSP shares user details with us via a custom endpoint.)' },
];

// Validation Schema
const validationSchema = Yup.object().shape({
  session_timeout_in_seconds: Yup.number().min(1, 'Must be greater than zero').required('Required'),
  ocpi_standard: Yup.string().required('Required'),
  charging_type_supported: Yup.string().required('Required'),
  calculation_type: Yup.string().required('Required'),
  value: Yup.number()
    .when("calculation_type", {
      is: "percentage",
      then: Yup.number()
        .min(0, "Must be at least 0")
        .max(100, "Cannot exceed 100")
        .required("Required"),
      otherwise: Yup.number().required("Required"),
    }),
});

const Configuration = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { ocpiId } = useParams();
  const { t } = useTranslation();
  let ocpiData = useSelector((state) => state.partnerManagement.partnerInfo) || {};
  if (ocpiData) {

    ocpiData = { ...ocpiData, calculation_type: ocpiData?.calculation?.calculation_type, value: ocpiData?.calculation?.value, }
  }

  const initialValues = ocpiId ? {
    is_session_push_supported: ocpiData.is_session_push_supported,
    session_timeout_in_seconds: ocpiData.session_timeout_in_seconds,
    is_reserve_now_supported: ocpiData.is_reserve_now_supported,
    is_ocpi_customer_supported: ocpiData.is_ocpi_customer_supported,
    allow_credential_post_as_response: ocpiData.allow_credential_post_as_response,
    is_fleet_details_supported: ocpiData.is_fleet_details_supported,
    is_limit_location_enable: ocpiData.is_limit_location_enable,
    is_connector_patch_supported: ocpiData.is_connector_patch_supported,
    is_custom_business_details_supported: ocpiData.is_custom_business_details_supported,
    ocpi_standard: ocpiData.ocpi_standard,
    charging_type_supported: ocpiData.charging_type_supported[0],
    calculation_type: ocpiData.calculation_type,
    value: ocpiData.value

  } : {
    ...appChecklistItems.reduce((acc, item) => ({ ...acc, [item.key]: item.default }), {}),
    ...coreChecklistItems.reduce((acc, item) => ({ ...acc, [item.key]: item.default }), {}),
    ...financeChecklistItems.reduce((acc, item) => ({ ...acc, [item.key]: item.default }), {}),
    session_timeout_in_seconds: 900,
    ocpi_standard: "2.2.1",
    charging_type_supported: "online",
    calculation_type: 'fixed_amount',
    value: 0
  };

  return (
    <LayoutContainer>
      <Card className="configuration-card">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(data) => {
            const payload = { ...data, calculation: { calculation_type: data.calculation_type, value: Number(data.value) }, id: ocpiData?.id };
            delete payload.value;
            delete payload.calculation_type;
            dispatch({ type: UPDATE_CPO.REQUEST, payload, cb: () => navigate('/OCPI-Partner') });
          }}
        >
          {({ handleSubmit, handleChange, values, errors, setFieldTouched, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <div className="checklist-container">

                <section >
                  <span className="section_title">Core Functionality</span>
                  <Row>
                    {coreChecklistItems.map((item) => (
                      <Col lg={6} md={6} sm={12} key={item.key} className="checklist-item">
                        <div className="form-group">
                          <div className="switch-container">
                            <label htmlFor={item.key} className="checklist-label">{item.label}</label>
                            <ToggleSwitch
                              name={item.key}
                              checked={values[item.key] || false}
                              onChange={(e) => setFieldValue(item.key, e.target.checked)}
                              className="custom-switch"
                            />
                          </div>
                          <small className="field-description">{item.description}</small>
                        </div>
                      </Col>
                    ))}
                  </Row>

                  <Row>
                    <Col>
                      <label htmlFor="value" className="checklist-label">{'Session Timeout (Seconds)'}<br /><br />
                        <small className="field-description">Enter the session timeout in seconds.</small>
                      </label>
                      <TextInput
                        isRequired={true}
                        name="session_timeout_in_seconds"
                        type="number"
                        placeholder="Enter session timeout (in seconds)"
                        value={values?.session_timeout_in_seconds}
                        onChange={handleChange}
                        onTouchStart={() => {
                          setFieldTouched("session_timeout_in_seconds", true);
                        }}
                        error={errors.session_timeout_in_seconds ? "Session timeout is required!" : null}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <label htmlFor="value" className="checklist-label">{'OCPI Standard'}<br /><br />
                        <small className="field-description">(Select OCPI version: 2.2 or 2.2.1.)</small>
                      </label>

                      <Select
                        isRequired={ocpiData ? false : true}
                        options={ocpiOptions}
                        placeholder={'ocpi standard'}
                        name="ocpi_standard"
                        value={values?.ocpi_standard}
                        onMenuScrollDown={true}
                        onChange={(val) => {
                          setFieldValue(`ocpi_standard`, val);
                        }}

                        error={errors.ocpi_standard ? "OCPI version is required!" : null}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <label htmlFor="value" className="checklist-label">{'Charging Type Supported'}<br /><br />
                        <small className="field-description">(Select charging type: offline, both, or online.)</small>
                      </label>
                      <Select
                        isRequired={ocpiData ? false : true}
                        options={chargingTypeOptions}
                        placeholder={'charging type supported'}
                        name="charging_type_supported"
                        value={values?.charging_type_supported}
                        onMenuScrollDown={true}
                        onChange={(val) => {
                          setFieldValue(`charging_type_supported`, val);
                        }}
                        error={errors.charging_type_supported ? "Charging type is required!" : null}
                      />
                    </Col>
                  </Row>
                </section>
                <br />
                <section >
                  <span className="section_title">Finance </span>
                  <Row>
                    {financeChecklistItems.map((item) => (
                      <Col lg={6} md={6} sm={12} key={item.key} className="checklist-item">
                        <div className="form-group">
                          <div className="switch-container">
                            <label htmlFor={item.key} className="checklist-label">{item.label}</label>
                            <ToggleSwitch
                              name={item.key}
                              checked={values[item.key] || false}
                              onChange={(e) => setFieldValue(item.key, e.target.checked)}
                              className="custom-switch"
                            />
                          </div>
                          <small className="field-description">{item.description}</small>
                        </div>
                      </Col>
                    ))}
                  </Row>

                  <Row>
                    <Col>
                      <label htmlFor="value" className="checklist-label">{'Discount Type'}<br /><br />
                        <small className="field-description">(Define calculation method: percentage or fixed amount.)</small>
                      </label>
                      <Select
                        isRequired={ocpiData ? false : true}
                        options={calculationOptions}
                        placeholder={'calculation_type'}
                        name="Calculation Type"
                        value={values?.calculation_type}
                        onMenuScrollDown={true}
                        onChange={(val) => {
                          setFieldValue(`calculation_type`, val);
                        }}
                        error={errors.calculation_type ? "Select Calculation type!" : null}
                      />

                    </Col>
                  </Row>

                  <Row>

                    {/* Amount Input Field */}
                    <Col lg={6} md={6} sm={12}>
                      <div className="form-group">
                        <label htmlFor="value" className="checklist-label">
                          {values?.calculation_type === "percentage" ? "Amount (Percentage)" : "Amount (Fixed)"}
                        </label>
                        <small className="field-description">
                          {values?.calculation_type === "percentage"
                            ? "Enter a value between 0 - 100%."
                            : "Enter a fixed amount (no limit)."}
                        </small>
                        <TextInput
                          isRequired={true}
                          name="value"
                          type="number"
                          placeholder={
                            values?.calculation_type === "percentage"
                              ? "Enter percentage (0-100%)"
                              : "Enter fixed amount"
                          }
                          value={values?.value}
                          onChange={handleChange}
                          onTouchStart={() => {
                            setFieldTouched("value", true);
                          }}
                          error={errors.value ? "Amount is required!" : null}
                        />
                      </div>
                    </Col>
                  </Row>
                </section>
                <br />
                <section>
                  <span className="section_title">App Configuration</span>
                  <Row>
                    {appChecklistItems.map((item) => (
                      <Col lg={6} md={6} sm={12} key={item.key} className="checklist-item">
                        <div className="form-group">
                          <div className="switch-container">
                            <label htmlFor={item.key} className="checklist-label">{item.label}</label>
                            <ToggleSwitch
                              name={item.key}
                              checked={values[item.key] || false}
                              onChange={(e) => setFieldValue(item.key, e.target.checked)}
                              className="custom-switch"
                            />
                          </div>
                          <small className="field-description">{item.description}</small>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </section>
              </div>
              <div className="cpo_save_btn">
                <Button type="submit" className="cpo_save_btn__1">
                  {t('button.save')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Card>
    </LayoutContainer>
  );
};

export default Configuration;
