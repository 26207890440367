import {
    GET_SAP_OCPI_PARTNER_LIST,
    FETCH_SAP_OCPI_CREDS_LIST,
    ADD_SAP_OCPI_PARTNER_LIST,
    UPDATE_SAP_OCPI_PARTNER_LIST,
    DELETE_SAP_OCPI_PARTNER_LIST,
    GENERATE_SAP_INVOICES_WITH_GST,
    DOWNLOAD_SAP_INVOICES_WITH_GST,
    TRIGGER_LOGITAX_OCPI_INVOICES,
    FETCH_SAP_INVOICES_WITH_GST
} from 'actions/sap';

const initialState = {
    isLoading: false,
    sapData: [],
    sapCredData: [],
    sapInvoiceData: [],
    logitaxData: [],
    sapFetchedData: []
};

const sapData = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_SAP_OCPI_PARTNER_LIST.REQUEST:
        case FETCH_SAP_OCPI_CREDS_LIST.REQUEST:
        case ADD_SAP_OCPI_PARTNER_LIST.REQUEST:
        case UPDATE_SAP_OCPI_PARTNER_LIST.REQUEST:
        case DELETE_SAP_OCPI_PARTNER_LIST.REQUEST:
        case GENERATE_SAP_INVOICES_WITH_GST.REQUEST:
        case DOWNLOAD_SAP_INVOICES_WITH_GST.REQUEST:
        case TRIGGER_LOGITAX_OCPI_INVOICES.REQUEST:
        case FETCH_SAP_INVOICES_WITH_GST.REQUEST:
            {
                return { ...state, isLoading: true };
            }

        case GET_SAP_OCPI_PARTNER_LIST.SUCCESS:
            {
                return {
                    ...state,
                    isLoading: false,
                    sapData: payload.data,
                    page: payload.page,
                    limit: payload.limit,
                    totalPages: payload.totalPages,
                    total: payload.totalResults,
                };
            }

        case GENERATE_SAP_INVOICES_WITH_GST.SUCCESS:
            {
                return {
                    ...state,
                    isLoading: false,
                    sapInvoiceData: payload.data,
                    page: payload.page,
                    limit: payload.limit,
                    totalPages: payload.totalPages,
                    total: payload.totalResults,
                };
            }



        case FETCH_SAP_INVOICES_WITH_GST.SUCCESS:
            {
                return {
                    ...state,
                    isLoading: false,
                    sapFetchedData: payload.data,
                    page: payload.page,
                    limit: payload.limit,
                    totalPages: payload.totalPages,
                    total: payload.totalResults,
                };
            }

        case ADD_SAP_OCPI_PARTNER_LIST.SUCCESS:
        case UPDATE_SAP_OCPI_PARTNER_LIST.SUCCESS:
        case DELETE_SAP_OCPI_PARTNER_LIST.SUCCESS:
            {
                return { ...state, isLoading: true };
            }

        case DOWNLOAD_SAP_INVOICES_WITH_GST.SUCCESS:
            {
                return { ...state, isLoading: true };
            }

        case FETCH_SAP_OCPI_CREDS_LIST.SUCCESS:
            return {
                ...state,
                isLoading: false,
                sapCredData: payload.data,
            }

        case TRIGGER_LOGITAX_OCPI_INVOICES.SUCCESS:
            return {
                ...state,
                isLoading: false,
                logitaxData: payload.data,
            }

        case GET_SAP_OCPI_PARTNER_LIST.FAIL:
        case FETCH_SAP_OCPI_CREDS_LIST.FAIL: {
            return { ...state, isLoading: false };
        }

        case ADD_SAP_OCPI_PARTNER_LIST.FAIL:
        case UPDATE_SAP_OCPI_PARTNER_LIST.FAIL:
        case DELETE_SAP_OCPI_PARTNER_LIST.FAIL:
            {
                return { ...state, isLoading: false };
            }


        case GENERATE_SAP_INVOICES_WITH_GST.FAIL:
            {
                return { ...state, isLoading: false };
            }

        case DOWNLOAD_SAP_INVOICES_WITH_GST.FAIL:
            {
                return { ...state, isLoading: false };
            }

        case TRIGGER_LOGITAX_OCPI_INVOICES.FAIL:
            {
                return { ...state, isLoading: false };
            }

        default:
            return { ...state };
    }
};
export default sapData;
