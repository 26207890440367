import React, { useCallback, useEffect, useContext, useState } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header/index';
import Card from 'components/inputs/Card';
import { Formik, Form } from 'formik';
import { Row, Col } from 'react-bootstrap';
import TextInput from 'components/inputs/Input';
import Select from 'components/inputs/Select';
import Button from 'components/inputs/Button';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { CREATE_STATUS_CODE, UPDATE_STATUS_CODE } from 'components/common/constant';
import { useTranslation } from 'react-i18next';
import Footer from 'components/general/Footer';
import { NavContext } from 'components/privateRoute/PrivateRoute';
import { ADD_SAP_OCPI_PARTNER_LIST, FETCH_SAP_OCPI_CREDS_LIST, GET_SAP_OCPI_PARTNER_LIST, UPDATE_SAP_OCPI_PARTNER_LIST } from 'actions/sap';
import { FETCH_STATE } from 'actions/address';
import { toast } from 'react-toastify';

const CreateOcpiPosting = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { navigateTo } = useContext(NavContext);
  const { partnerId } = useParams();
  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserName = _.get(profileData, 'name', '');
  const allOcpiCreds = useSelector((state) => state.sapData.sapCredData);
  const allState = useSelector((state) => state.state.states);
  const allSapOcpiPartnerDetails = useSelector((state) => state.sapData.sapData);

  const [gstRows, setGstRows] = useState([{ state: '', gstNumber: '', isDefault: true }]);
  const [ocpiPartnerRows, setOcpiPartnerRows] = useState([
    { partner: '', detail1: '', detail2: '', detail3: '', detail4: '' }
  ]);

  const [initialValues, setInitialValues] = useState({
    name: '',
    email: '',
    phone: '',
    sapCustomerId: '',
  });

  useEffect(() => {
    if (partnerId && Array.isArray(allSapOcpiPartnerDetails) && allSapOcpiPartnerDetails.length) {
      const selectedPartner = allSapOcpiPartnerDetails.find(p => p._id === partnerId);
      if (selectedPartner) {
        // Basic Details
        setInitialValues({
          name: selectedPartner.name || '',
          email: selectedPartner.email || '',
          phone: selectedPartner.phone || '',
          sapCustomerId: selectedPartner.sap_customer_id || '',
        });

        // GST Details
        if (Array.isArray(selectedPartner.gst_details)) {
          const formattedGst = selectedPartner.gst_details.map(g => ({
            state: g.state,
            gstNumber: g.gst_number,
            isDefault: g.is_default,
          }));
          setGstRows(formattedGst);
        }

        // OCPI Partner Details
        if (Array.isArray(selectedPartner.ocpi_credentials)) {
          const formattedOcpi = selectedPartner.ocpi_credentials.map(ocpiId => {
            const cred = allOcpiCreds.find(c => c.ocpiId === ocpiId);
            return {
              partner: ocpiId,
              detail1: cred?.tenant_name || '',
              detail2: cred?.role || '',
              detail3: cred?.partner_name || '',
              detail4: cred?.party_id || '',
            };
          });
          setOcpiPartnerRows(formattedOcpi.length ? formattedOcpi : [{ partner: '', detail1: '', detail2: '', detail3: '' }]);
        }
      }
    }
  }, [partnerId, allSapOcpiPartnerDetails, allOcpiCreds]);



  const getStateByCountry = useCallback(() => {
    dispatch({ type: FETCH_STATE.REQUEST, payload: { deleted: true, limit: 9999 } });
  }, [dispatch]);

  const fetchSapOcpiCredsList = useCallback(() => {
    dispatch({ type: FETCH_SAP_OCPI_CREDS_LIST.REQUEST });
  }, []);

  const addSapOcpiPartner = useCallback((data) => {
    const actionType = partnerId ? UPDATE_SAP_OCPI_PARTNER_LIST.REQUEST : ADD_SAP_OCPI_PARTNER_LIST.REQUEST;

    dispatch({
      type: actionType,
      payload: partnerId ? { ...data, id: partnerId } : data,
      cb: (res) => {
        if (_.get(res, 'status') === CREATE_STATUS_CODE || UPDATE_STATUS_CODE) {
          setTimeout(() => {
            navigateTo('/OcpiPosting');
            toast.success(partnerId ? "Data Updated Successfully" : "Data Added Successfully")
          }, 1000);
        }
      },
    });
  }, [dispatch, navigateTo]);


  const getSapOcpiPartnerData = useCallback(() => {
    dispatch({
      type: GET_SAP_OCPI_PARTNER_LIST.REQUEST,
    });
  }, [dispatch, navigateTo]);

  const handleGstChange = (index, field, value) => {
    const updatedRows = [...gstRows];
    updatedRows[index][field] = value;
    setGstRows(updatedRows);
  };

  const addGstRow = () => {
    setGstRows([...gstRows, { state: '', gstNumber: '' }]);
  };

  const removeGstRow = (index) => {
    setGstRows(gstRows.filter((_, i) => i !== index));
  };

  const handleOcpiPartnerChange = (index, selectedOcpiId) => {
    const selectedCred = _.find(allOcpiCreds, { ocpiId: selectedOcpiId });
    const updatedRows = [...ocpiPartnerRows];
    updatedRows[index] = {
      partner: selectedOcpiId,
      detail1: selectedCred?.tenant_name || '',
      detail2: selectedCred?.role || '',
      detail3: selectedCred?.partner_name || '',
      detail4: selectedCred?.party_id || '',
    };
    setOcpiPartnerRows(updatedRows);
  };

  const handleDefaultCheckboxChange = (index) => {
    const updatedRows = gstRows.map((row, i) => ({
      ...row,
      isDefault: i === index, // only the selected index gets true
    }));
    setGstRows(updatedRows);
  };

  const addOcpiPartnerRow = () => {
    setOcpiPartnerRows([
      ...ocpiPartnerRows,
      { partner: '', detail1: '', detail2: '', detail3: '' }
    ]);
  };

  const removeOcpiPartnerRow = (index) => {
    setOcpiPartnerRows(ocpiPartnerRows.filter((_, i) => i !== index));
  };

  useEffect(() => {
    fetchSapOcpiCredsList();
    getStateByCountry();
    getSapOcpiPartnerData();
  }, []);

  return (
    <LayoutContainer>
      <AdminHeader title={`${partnerId ? 'Edit' : 'Add'} ${t('sap.ocpiPartner')}`} backTo="/OcpiPosting" />
      <div className="page-content-wrapper scrollable">
        <div className="add-vehicle-page-main">
          <Card className="vehicle-form-card">
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              onSubmit={(values, { setSubmitting }) => {
                const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
                const phoneRegex = /^[0-9]{10}$/;
                const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                const noSpecialCharRegex = /^[a-zA-Z0-9 ]+$/;
                const noSpecialCharNoSpaceRegex = /^[a-zA-Z0-9]+$/;


                // 1. Validate Name
                if (!values.name.trim() || !isNaN(values.name.trim()) || !noSpecialCharRegex.test(values.name.trim())) {
                  toast.error('Name is mandatory, cannot be a number, and should not contain special characters.');
                  setSubmitting(false);
                  return;
                }

                // 2. Validate Email (optional)
                if (values.email && !emailRegex.test(values.email.trim())) {
                  toast.error('Please enter a valid email address.');
                  setSubmitting(false);
                  return;
                }

                // 3. Validate SAP Customer ID
                if (!values.sapCustomerId.trim() || !noSpecialCharNoSpaceRegex.test(values.sapCustomerId.trim())) {
                  toast.error('SAP Customer ID is mandatory and should not contain special characters or spaces.');
                  setSubmitting(false);
                  return;
                }

                // 4. Validate at least one GST detail entry
                if (gstRows.length === 0) {
                  toast.error('At least one GST detail entry is required.');
                  setSubmitting(false);
                  return;
                }

                // GST details format validation
                const hasInvalidGstEntry = gstRows.some(
                  row =>
                    !row.state ||
                    !row.gstNumber ||
                    !gstRegex.test(row.gstNumber.trim().toUpperCase())
                );

                // Duplicate GST number or state check
                const hasDuplicateGstNumber = new Set(gstRows.map(r => r.gstNumber.trim().toUpperCase())).size !== gstRows.length;
                const hasDuplicateGstState = new Set(gstRows.map(r => r.state)).size !== gstRows.length;

                if (hasInvalidGstEntry || hasDuplicateGstNumber || hasDuplicateGstState) {
                  toast.error('GST details must be complete, correctly formatted, and without duplicates.');
                  setSubmitting(false);
                  return;
                }

                // 5. Validate at least one OCPI partner detail entry
                if (ocpiPartnerRows.length === 0) {
                  toast.error('At least one OCPI partner detail entry is required.');
                  setSubmitting(false);
                  return;
                }

                const hasDuplicateOcpiPartners = new Set(ocpiPartnerRows.map(r => r.partner)).size !== ocpiPartnerRows.length;
                const hasEmptyOcpiField = ocpiPartnerRows.some(row => !row.partner);

                if (hasEmptyOcpiField || hasDuplicateOcpiPartners) {
                  toast.error('Each OCPI Partner must be selected only once and cannot be blank.');
                  setSubmitting(false);
                  return;
                }

                // Validate Phone (optional)
                if (values.phone && !phoneRegex.test(values.phone.trim())) {
                  toast.error('Please enter a valid 10-digit phone number.');
                  setSubmitting(false);
                  return;
                }

                // Build payload
                const payload = {
                  name: values.name.trim(),
                  sap_customer_id: values.sapCustomerId.trim(),
                  gst_details: gstRows.map(row => ({
                    state: row.state,
                    gst_number: row.gstNumber.trim().toUpperCase(),
                    is_default: row.isDefault,
                  })),
                  ocpi_credentials: ocpiPartnerRows.map(row => row.partner),
                  phone: values.phone.trim() || "",
                  email: values.email.trim() || ""
                };

                // Conditionally include email and phone
                if (values.email) {
                  payload.email = values.email.trim();
                }

                if (values.phone) {
                  payload.phone = values.phone.trim();
                }

                addSapOcpiPartner(payload);
                setSubmitting(false);
              }}
            >

              {({ values, errors, touched, handleSubmit, handleChange }) => (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col lg={6}>
                      <TextInput
                        isRequired
                        label={t('sap.name')}
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        error={touched.name && errors.name}
                      />
                    </Col>
                    <Col lg={6}>
                      <TextInput
                        label={t('sap.email')}
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        error={touched.email && errors.email}
                      />
                    </Col>
                    <Col lg={6}>
                      <TextInput
                        isRequired
                        label={t('sap.sapCustomerId')}
                        name="sapCustomerId"
                        value={values.sapCustomerId}
                        onChange={handleChange}
                        error={touched.sapCustomerId && errors.sapCustomerId}
                      />
                    </Col>
                    <Col lg={6}>
                      <TextInput
                        label={t('sap.phone')}
                        name="phone"
                        value={values.phone}
                        onChange={handleChange}
                        error={touched.phone && errors.phone}
                      />
                    </Col>
                  </Row>

                  <h4 className="mt-4">{t('GST Details')}</h4>
                  <hr />
                  {gstRows.map((row, index) => (
                    <Row key={`gst-${index}`} className="align-items-center mb-3">
                      <Col lg={4}>
                        <Select
                          label="State"
                          options={allState
                            .filter(s => {
                              const alreadySelected = gstRows.map((r, i) => i !== index && r.state);
                              return !alreadySelected.includes(s.name);
                            })
                            .map((s) => ({ label: s.name, value: s.name }))
                          }
                          value={row.state}
                          onChange={(val) => handleGstChange(index, 'state', val)}
                          placeholder="Select State"
                        />
                      </Col>
                      <Col lg={3}>
                        <TextInput
                          label="GST Number"
                          value={row.gstNumber}
                          onChange={(e) => handleGstChange(index, 'gstNumber', e.target.value)}
                          placeholder="Enter GST Number"
                        />
                      </Col>
                      <Col lg={1}>
                        <div className="form-check mt-4">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={row.isDefault}
                            onChange={() => handleDefaultCheckboxChange(index)}
                            id={`default-gst-${index}`}
                          />
                          <label className="form-check-label" htmlFor={`default-gst-${index}`}>
                            Default
                          </label>
                        </div>
                      </Col>
                      <Col lg={4} className="d-flex gap-2">
                        {gstRows.length > 1 && (
                          <Button type="button" className="btn btn-danger" onClick={() => removeGstRow(index)}>
                            Remove
                          </Button>
                        )}
                        {index === gstRows.length - 1 && (
                          <Button type="button" className="btn btn-primary" onClick={addGstRow}>
                            + Add
                          </Button>
                        )}
                      </Col>
                    </Row>
                  ))}

                  <h4 className="mt-4">{t('List of OCPI Partner Details')}</h4>
                  <hr />
                  {ocpiPartnerRows.map((row, index) => (
                    <Row key={`ocpi-${index}`} className="align-items-center mb-3">
                      <Col lg={12}>
                        <Select
                          label="Partner Details"
                          options={allOcpiCreds
                            .filter((p) => {
                              const selectedPartners = ocpiPartnerRows.map((r, i) => i !== index && r.partner);
                              return !selectedPartners.includes(p.ocpiId);
                            })
                            .map((p) => ({
                              label: `${p.party_id}, ${p.tenant_name}, ${p.role}, ${p.partner_name}`,
                              value: p.ocpiId,
                            }))
                          }
                          value={row.partner}
                          onChange={(val) => handleOcpiPartnerChange(index, val)}
                          placeholder="Select Partner Details"
                        />
                      </Col>
                      <Col lg={2}><TextInput label="Party ID" value={row.detail4} disabled /></Col>
                      <Col lg={2}><TextInput label="Tenant" value={row.detail1} disabled /></Col>
                      <Col lg={2}><TextInput label="Role" value={row.detail2} disabled /></Col>
                      <Col lg={3}><TextInput label="Partner Name" value={row.detail3} disabled /></Col>
                      <Col lg={3} className="d-flex gap-2">
                        {ocpiPartnerRows.length > 1 && (
                          <Button type="button" className="btn btn-danger" onClick={() => removeOcpiPartnerRow(index)}>
                            Remove
                          </Button>
                        )}
                        {index === ocpiPartnerRows.length - 1 && (
                          <Button type="button" className="btn btn-primary" onClick={addOcpiPartnerRow}>
                            + Add
                          </Button>
                        )}
                      </Col>
                    </Row>
                  ))}

                  <div className="vehicle-save--btn--block">
                    <Button type="submit" className="vehicle-save-btn save--btn">
                      {partnerId ? 'Update' : 'Save'}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Card>
        </div>
        {currentUserName !== 'CZ Tenant Admin' && <Footer />}
      </div>
    </LayoutContainer>
  );
};

export default CreateOcpiPosting;
