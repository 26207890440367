import { all, fork } from 'redux-saga/effects';
import auth from './authSaga';
import tenant from './tenant';
import chargingStation from './chargingStation';
import vehicle from './vehicle';
import charger from './charger';
import invoice from './invoice';
import chargerBooking from './chargerBooking';
import user from './user';
import oem from './oem';
import amenity from './amenity';
import subscription from './subscription';
import profile from './profile';
import otp from './otp';
import customer from './customer';
import address from './address';
import notification from './notification';
import tariff from './tariff';
import vehicleMake from './vehicleMake';
import vehicleModel from './vehicleModel';
import activityLog from './activityLog';
import electricitySupplier from './electricitySupplier';
import stationAccessType from './stationAccessType';
import oemVendor from './oemVendor';
import faq from './faq';
import privacy from './privacy';
import connectorType from './connectorType';
import chargeSpeed from './chargeSpeed';
import role from './role';
import dashboard from './dashboard';
import chargerOcpp from './chargerOcpp';
import chargerState from './chargerState';
import oemErrorCode from './oemErrorCode';
import dataReport from './dataReport';
import generateQrCode from './generateQrCode';
import permissions from './permissions';
import offers from './offers';
import liveChargingSessionSaga from './liveChargingSession';
import userGroup from './userGroup';
import sms from './sms';
import email from './email';
import module from './module';
import rfid from './rfid';
import rfidGroup from './rfidGroup';
import masterSetting from './masterSetting';
import rfidRequest from './rfidRequest';
import chargerStatus from './chargerStatus';
import reserveSlots from './reserveSlots';
import partnerManagement from './partnerManagement';
import chargeCoin from './chargeCoin';
import tenantSetting from './tenantSetting';
import tenantAdminAccess from './tenantAdminAccess';
import capacityUtilisation from './capacity_saga';
// import updateNegative from './negativesaga';
import schedulingSetting from './schedulingSetting';
import schedule from './schedule';
import communicationManager from './communicationManager';
import smcGateway from './smcGateway';
import tagmanagement from './tagmanagement';
import rating from './rating';
import noLoadReport from './noLoadReport';
import updateBulkNegativeValue from './updateBulkNegativeValues';
import accountDeleteRequest from './accountDeleteRequest';
import partnerGroup from './ocpiPartnerGroup';
import reportDownloadRequest from './downloadAdvanceReport';
import electricityBillReport from './electricityBillReport';
import ownershipType from './ownershipType';
import voucher from './voucher';
import coupon from './coupon';
import chargerLogGraph from './chargerLogGraph';
import sap from './sap'
import alerts from './alerts';

export default function* rootSaga() {
  yield all([
    // fork(updateNegative),
    fork(electricityBillReport),
    fork(partnerGroup),
    fork(capacityUtilisation),
    fork(auth),
    fork(tenant),
    fork(chargingStation),
    fork(vehicle),
    fork(charger),
    fork(invoice),
    fork(chargerBooking),
    fork(user),
    fork(oem),
    fork(amenity),
    fork(subscription),
    fork(profile),
    fork(otp),
    fork(customer),
    fork(address),
    fork(notification),
    fork(tariff),
    fork(vehicleMake),
    fork(vehicleModel),
    fork(activityLog),
    fork(electricitySupplier),
    fork(stationAccessType),
    fork(oemVendor),
    fork(faq),
    fork(privacy),
    fork(connectorType),
    fork(chargeSpeed),
    fork(role),
    fork(dashboard),
    fork(chargerOcpp),
    fork(chargerState),
    fork(oemErrorCode),
    fork(dataReport),
    fork(generateQrCode),
    fork(permissions),
    fork(offers),
    fork(liveChargingSessionSaga),
    fork(userGroup),
    fork(sms),
    fork(email),
    fork(module),
    fork(rfid),
    fork(rfidGroup),
    fork(masterSetting),
    fork(rfidRequest),
    fork(chargerStatus),
    fork(reserveSlots),
    fork(partnerManagement),
    fork(chargeCoin),
    fork(tenantSetting),
    fork(tenantAdminAccess),
    fork(schedulingSetting),
    fork(schedule),
    fork(communicationManager),
    fork(smcGateway),
    fork(tagmanagement),
    fork(rating),
    fork(noLoadReport),
    fork(updateBulkNegativeValue),
    fork(accountDeleteRequest),
    fork(reportDownloadRequest),
    fork(voucher),
    fork(coupon),
    fork(chargerLogGraph),
    fork(ownershipType),
    fork(alerts),
    fork(sap)
  ]);
}