import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Card from 'components/inputs/Card';
import { MdClose } from 'react-icons/md';
import { Row, Col } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import _ from 'lodash';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { CREATE_VEHICLE_MODEL, UPDATE_VEHICLE_MODEL } from 'actions/vehicleModel';
import Select from 'components/inputs/Select';
import { FETCH_VEHICLE_MAKE } from 'actions/vehicleMake';
import { CREATE_STATUS_CODE, UPDATE_STATUS_CODE } from 'components/common/constant';
import axios from 'axios';
import { cookie } from 'utils/cookies/cookies';
import { toast } from 'react-toastify';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';

const ModelForm = (props) => {
  const { isEdit, onClose = _.noop(), onSuccess = _.noop() } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const token = cookie.get('token');
  const modelDetail = useSelector((state) => state.vehicleModel.vehicleModelDetail);
  const makeDetail = useSelector((state) => state.vehicleMake.vehicleMakes);
  const makePage = useSelector((state) => state.vehicleMake.page);
  const makeTotalPage = useSelector((state) => state.vehicleMake.totalPages);
  const isLoading = useSelector((state) => state.vehicleModel.isLoading);

  const addVehicleModel = useCallback((data) => {
    dispatch({
      type: CREATE_VEHICLE_MODEL.REQUEST,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === CREATE_STATUS_CODE) {
          setTimeout(() => {
            window.location.reload();
            onSuccess();
            onClose();
          }, 1000);
        }
      },
    });
  }, []);

  const updateVehicleModel = useCallback((data) => {
    dispatch({
      type: UPDATE_VEHICLE_MODEL.REQUEST,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          setTimeout(() => {
            window.location.reload();
            onClose();
          }, 1000);
        }
      },
    });
  }, []);

  const getAllVehicleMake = useCallback((data = {}) => {
    dispatch({ type: FETCH_VEHICLE_MAKE.REQUEST, payload: data });
  }, []);

  useEffect(() => {
    getAllVehicleMake();
  }, []);

  const handleImage = (image, savePath, setFieldValue) => {
    const fileName = _.get(image, 'name', '');

    axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: process.env.REACT_APP_API_URL + `/util/fileupload?name=${fileName}`,
    })
      .then((res) => {
        if (res.status === 201) {
          const signedRequest = _.get(res, 'data.signedRequest', '');
          handleUploadFile(signedRequest, image, savePath, setFieldValue);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((e) => console.error('Error -> ', e));
  };

  const handleUploadFile = (url, imageData, savePath, setFieldValue) => {
    const fileType = _.get(imageData, 'type', '');

    axios({
      method: 'put',
      headers: {
        'Content-Type': fileType,
        'Content-Encoding': 'base64',
      },
      url: url,
      data: imageData,
    })
      .then((res) => {
        if (res.status === 200) {
          const imgUrlData = queryString.parseUrl(url);
          setFieldValue(savePath, imgUrlData.url);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((e) => console.error('Error -> ', e));
  };

  const ModelSchema = Yup.object().shape({
    makeId: Yup.string().required(t('modelForm.makeId')),
    // type: Yup.string().required(t('modelForm.type')),
    model: Yup.string()
      .required(t('modelForm.model'))
      .strict(true)
      .trim(t('modelForm.space'))
      .matches(/^[A-Za-z0-9\s]+$/, t('modelForm.matchNumAlpha'))
      .max(100, t('modelForm.maxlimit')),
    // year: Yup.string()
    //   .required(t('modelForm.year'))
    //   .strict(true)
    //   .trim(t('modelForm.space'))
    //   .matches(/^[0-9]+$/, t('modelForm.matchesNumber'))
    //   .min(4, t('modelForm.formatYear'))
    //   .max(4, t('modelForm.formatYear')),
    // vin_num: Yup.string()
    //   .required('VIN number is required')
    //   .strict(true)
    //   .trim('Space is not allowed')
    //   .matches(/^[A-Za-z0-9]+$/, 'Only alphabets and numbers are allowed'),
    // variant: Yup.string().required('Variant is required'),
    max_charging_capacity: Yup.string()
      .required('Battery capacity is required')
      .strict(true)
      .trim('Space is not allowed')
      .matches(/^(?:\d+|\d+\.\d+)$/, 'Only numbers are allowed'),
    picture: Yup.mixed().nullable().required('Model Picture is required'),
  });

  const initialValues = isEdit
    ? { ...modelDetail, type: _.get(modelDetail, 'makeId.type', ''), makeId: _.get(modelDetail, 'makeId.id', '') }
    : {
        makeId: '',
        model: '',
        type: '',
        year: '',
        variant: '',
        // vin_num: '',
        max_charging_capacity: '',
        picture: '',
      };
  return (
    <React.Fragment>
      <div className="model-form-page__main">
        <Card>
          <div className="model-form-header__block">
            <div className="model-header-name">
              <span>
                {isEdit ? 'Edit' : 'Add'} {t('modelForm.title')}
              </span>
            </div>
            <div className="close-btn">
              <MdClose size={30} color="#be210b" onClick={onClose} />
            </div>
          </div>
          <div className="model-form-body__block">
            <div className="model-form--block">
              <Formik
                enableReinitialize={!!isEdit}
                initialValues={initialValues}
                validationSchema={ModelSchema}
                onSubmit={(values, { setSubmitting }) => {
                  const updatedValues = { ...values, is_new_data: true };
                
                  if (isEdit) {
                    updateVehicleModel(updatedValues);
                  } else {
                    addVehicleModel(updatedValues);
                  }
                
                  setSubmitting(false);
                }}
              >
                {({ values, errors, touched, handleSubmit, handleChange, setFieldValue, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      {/* <Col lg={12} md={12}>
                        <Select
                          isRequired
                          label={t('modelForm.vehicleType')}
                          placeholder={t('placeHolder.selectVehicleType')}
                          options={[
                            { label: 'Bus', value: 'bus' },
                            { label: 'Car', value: 'car' },
                            { label: 'Two wheeler', value: 'two wheeler' },
                            { label: 'Three wheeler', value: 'three wheeler' },
                            { label: 'Other', value: 'other' },
                          ]}
                          name="type"
                          // value={values.type}
                          value={_.get(values, 'type', '')}
                          onChange={(val) => setFieldValue('type', val)}
                          error={errors.type && touched.type ? errors.type : null}
                        />
                      </Col> */}
                      <Col lg={12} md={12}>
                        <Select
                          isRequired
                          label={t('modelForm.vehicleType')}
                          options={
                            _.map(makeDetail, (item) => {
                              return { label: item.name, value: item.id };
                            })
                          }
                          onMenuScrollDown={true}
                          getDataOnScrollDown={getAllVehicleMake}
                          page={makePage}
                          totalPage={makeTotalPage}
                          placeholder={t('modelForm.makeId')}
                          value={_.get(values, 'makeId', '')}
                          name="makeId"
                          onChange={(val) => setFieldValue(`makeId`, val)}
                          error={errors.makeId && touched.makeId ? errors.makeId : null}
                        />
                      </Col>
                      <Col lg={12} md={12}>
                        <TextInput
                          isRequired
                          label={t('modelForm.evModelName')}
                          placeholder={t('modelForm.evModelName')}
                          name="model"
                          value={_.get(values, 'model', '')}
                          onChange={handleChange}
                          error={errors.model && touched.model ? errors.model : null}
                        />
                      </Col>
                      {/* <Col lg={12} md={12}>
                        <TextInput
                          isRequired
                          label={t('modelForm.variant')}
                          placeholder={t('modelForm.variant')}
                          name="variant"
                          value={_.get(values, 'variant', '')}
                          onChange={handleChange}
                          error={errors.variant && touched.variant ? errors.variant : null}
                        />
                      </Col> */}
                      <Col lg={6} md={12}>
                        <TextInput
                          isRequired
                          type="file"
                          label={t('modelForm.file')}
                          name="picture"
                          accept="image/*"
                          onChange={(e) => handleImage(e.currentTarget.files[0], 'picture', setFieldValue)}
                          error={errors.picture && touched.picture ? errors.picture : null}
                        />
                      </Col>
                      <Col lg={6} md={12}>
                        <Select
                          label={t('modelForm.priority')}
                          options={[
                            { label: '1', value: 1 },
                            { label: '2', value: 2 },
                            { label: '3', value: 3 },
                            { label: '4', value: 4 },
                            { label: '5', value: 5 },
                          ]}
                          onMenuScrollDown={true}
                          placeholder={t('modelForm.priority')}
                          value={_.get(values, 'priority', '')}
                          name="priority"
                          onChange={(val) => setFieldValue(`priority`, val)}
                        />
                      </Col>
                      {/* <Col lg={6} md={12}>
                        <TextInput
                          isRequired
                          label={t('modelForm.yearLabel')}
                          placeholder={t('modelForm.yearFormatLabel')}
                          name="year"
                          value={values.year}
                          onChange={handleChange}
                          error={errors.year && touched.year ? errors.year : null}
                        />
                      </Col> */}
                      {/* <Col lg={6} md={12}>
                        <TextInput
                          isRequired
                          label="VIN number"
                          placeholder="VIN number"
                          name="vin_num"
                          value={values.vin_num}
                          onChange={handleChange}
                          error={errors.vin_num && touched.vin_num ? errors.vin_num : null}
                        />
                      </Col> */}
                      <Col lg={6} md={12}>
                        <TextInput
                          isRequired
                          post_text="kWh"
                          label={t('modelForm.batteryCapacity')}
                          placeholder={t('modelForm.batteryCapacity')}
                          name="max_charging_capacity"
                          value={values.max_charging_capacity}
                          onChange={handleChange}
                          error={errors.max_charging_capacity && touched.max_charging_capacity ? errors.max_charging_capacity : null}
                        />
                      </Col>
                    </Row>
                    <div className="form_btn--block">
                      <Button className="form--btn cancel_btn" onClick={onClose}>
                        {t('button.cancel')}
                      </Button>
                      <Button type="submit" disabled={isSubmitting || isLoading} className="form--btn">
                        {isEdit ? 'Update' : 'Create'}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};
ModelForm.propTypes = {
  onSuccess: PropTypes.func,
  onClose: PropTypes.func,
  isEdit: PropTypes.bool,
};
export default ModelForm;
