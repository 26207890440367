import React, { useState, useCallback, useEffect, useContext } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header';
import Card from 'components/inputs/Card';
import Button from 'components/inputs/Button';
import { FiEdit2 } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { Row, Col, Modal, Spinner } from 'react-bootstrap';
import * as _ from 'lodash';
import SearchBox from 'components/general/SearchBox';
import { useDispatch, useSelector } from 'react-redux';
import DeleteModal from 'components/general/DeleteModal';
// import { DELETE_STATUS_CODE } from 'components/common/constant';
import { useTranslation } from 'react-i18next';
import CreateOcpiPosting from './CreateOcpiPosting';
import { NavContext } from 'components/privateRoute/PrivateRoute';
import { DELETE_SAP_OCPI_PARTNER_LIST, GET_SAP_OCPI_PARTNER_LIST } from 'actions/sap';
import { UPDATE_STATUS_CODE } from 'components/common/constant';
import { toast } from 'react-toastify';


const OcpiPosting = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { navigateTo } = useContext(NavContext);
    const [showForm, setShowForm] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [partnerId, setPartnerId] = useState('');
    const [pageWiseSapData, setpageWiseSapData] = useState([]);
    const isLoader = useSelector((state) => state.country.isLoading);

    const getCountry = useCallback(
        (data = {}) => {
            if (searchText) {
                const searchCountryData = {
                    ...data,
                    search: searchText,
                };
                dispatch({
                    type: GET_SAP_OCPI_PARTNER_LIST.REQUEST,
                    payload: searchCountryData,
                    cb: (res) => {
                        if (res) {
                            if (_.get(res, 'status') === 200) {
                                setpageWiseSapData(res.data.data);
                            }
                        }
                    },
                });
            } else {
                dispatch({
                    type: GET_SAP_OCPI_PARTNER_LIST.REQUEST,
                    payload: data,
                    cb: (res) => {
                        if (res) {
                            if (_.get(res, 'status') === 200) {
                                setpageWiseSapData(res.data.data);
                            }
                        }
                    },
                });
            }
        },
        [pageWiseSapData, searchText]
    );

    const handleCloseDeleteModel = () => {
        setShowDeleteModal(false);
    };

    useEffect(() => {
        getCountry();
    }, []);

    const handleSearch = () => {
        getCountry({});
    };


    const handleCloseFormCountry = () => {
        setShowForm(false);
        setIsEdit(false);
    };

    const deleteSapOcpiPartnerList = useCallback(() => {
        dispatch({
            type: DELETE_SAP_OCPI_PARTNER_LIST.REQUEST,
            payload: partnerId,
            cb: (res) => {
                if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
                    toast.success("Data Deleted Successfully");
                    handleCloseDeleteModel();
                    getCountry();
                }
            },
        });
    }, [dispatch, partnerId, getCountry]);


    return (
        <>
            <LayoutContainer>
                <AdminHeader title={t('sap.ocpiMaster')} />
                <div className="page-content-wrapper scrollable">
                    <div className="countryList-page">
                        <Card>
                            <div className="countryList-page-wrapper">
                                <Row className="countryList-search-box">
                                    <Col xl={10} lg={9} md={8} className="countryList-search-inputBox">
                                        <SearchBox
                                            value={searchText}
                                            onChange={(e) => setSearchText(e.target.value)}
                                            onKeyPress={(e) => {
                                                if (e.key === 'Enter') {
                                                    handleSearch();
                                                }
                                            }}
                                        />
                                    </Col>
                                    <Col xl={2} lg={3} md={4} className="countryList-search-boxButton">
                                        <Button
                                            className="countryList-search-box-Btn"
                                            onClick={() => navigateTo('/CreateOcpiPosting')}
                                        >
                                            {'Add ' + t('sap.ocpiPartner')}
                                        </Button>
                                    </Col>
                                </Row>
                                <div className="countryList-table">
                                    <div className="table-responsive">
                                        <table className="record-list-table">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <span>{t('sap.partnerName')}</span>
                                                    </th>
                                                    <th>
                                                        <span>{t('sap.sapCustomerId')}</span>
                                                    </th>
                                                    <th>
                                                        <span>{t('sap.phone')}</span>
                                                    </th>
                                                    <th>
                                                        <span>{t('sap.email')}</span>
                                                    </th>
                                                    <th className="action-col">
                                                        <span>{t('sap.action')}</span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {isLoader ? (
                                                    <tr>
                                                        <td colSpan={10} className="border-0">
                                                            <div className="loader--block">
                                                                <Spinner as="span" animation="border" size="sm" role="status" />
                                                                <span className="ps-2">{t('placeHolder.loading')}</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ) : _.isEmpty(pageWiseSapData) ? (
                                                    <tr>
                                                        <td colSpan={6} className="border-0">
                                                            <div className="empty-data-block">{t('No data Found')}</div>
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    !(isLoader || _.isUndefined(isLoader)) &&
                                                    _.map(pageWiseSapData, (item, key) => {
                                                        return (
                                                            <tr key={`country-${key}`}>
                                                                <td>{_.get(item, 'name', '')}</td>
                                                                <td>{_.get(item, 'sap_customer_id', '')}</td>
                                                                <td>{_.get(item, 'phone', '')}</td>
                                                                <td>{_.get(item, 'email', '')}</td>
                                                                <td>
                                                                    <span
                                                                        className="countryList-table-editIcon"
                                                                        onClick={() => navigateTo(`/EditOcpiPosting/${_.get(item, '_id', '')}`)}
                                                                    >
                                                                        <FiEdit2 title="Edit" />
                                                                    </span>
                                                                    <span
                                                                        className="countryList-table-deleteIcon"
                                                                        onClick={() => {
                                                                            setShowDeleteModal(true);
                                                                            setPartnerId(_.get(item, '_id'))
                                                                        }}
                                                                    >
                                                                        <RiDeleteBinLine title="Delete" />
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
                {showForm && (
                    <Modal show={showForm} size="lg" centered>
                        <CreateOcpiPosting onClose={handleCloseFormCountry} isEdit={isEdit} onSuccess={getCountry} />
                    </Modal>
                )}
                {showDeleteModal && (
                    <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
                        <DeleteModal title="Partner" onClose={handleCloseDeleteModel} onRemove={deleteSapOcpiPartnerList} />
                    </Modal>
                )}
            </LayoutContainer>
        </>
    );
};
export default OcpiPosting;
