import { post, get, downloadFile, patch, deleteRequest} from 'utils/api';

export const sendSAPInvoiceB2CRecordsCGSTSGST = (data) =>{
  return post('/sapRoute/send-b2c-data-to-sap-cgst-sgst', data)
}
export const sendSAPInvoiceB2CRecordsIGST = (data) =>{
  return post('/sapRoute/send-b2c-data-to-sap-igst', data)
}

export const getSapMonthlyData = (data) =>{
  return get('/sapRoute/get-sap-data', data)
}
export const downloadInvoiceB2C = (data) =>{
  return downloadFile('/sapRoute/invoice-records-b2c', data)
}

export const sapFaultyBookingReport = (data) => {
  const page = data.page ? data.page : 1;
  if (data.sortBy && page && data.search) {
    const sortBy = data.sortBy;
    const search = data.search;
    delete data['page'];
    delete data['sortBy'];
    delete data['search'];
    return post(`/charger_bookings/sapFaultyBookings?page=${parseInt(page)}&sortBy=${sortBy}&search=${search}`, data);
  } else if (page && data.search) {
    const search = data.search;
    delete data['page'];
    delete data['search'];
    return post(`/charger_bookings/sapFaultyBookings?page=${parseInt(page)}&search=${search}`, data);
  } else if (data.sortBy && data.search) {
    const sortBy = data.sortBy;
    const search = data.search;
    delete data['search'];
    delete data['sortBy'];
    return post(`/charger_bookings/sapFaultyBookings?search=${search}&sortBy=${sortBy}`, data);
  } else if (data.sortBy && page) {
    const sortBy = data.sortBy;
    delete data['page'];
    delete data['sortBy'];
    return post(`/charger_bookings/sapFaultyBookings?page=${parseInt(page)}&sortBy=${sortBy}`, data);
  } else if (data.search) {
    const search = data.search;
    delete data['search'];
    return post(`/charger_bookings/sapFaultyBookings?search=${search}`, data);
  } else if (data.sortBy) {
    const sortBy = data.sortBy;
    delete data['sortBy'];
    return post(`/charger_bookings/sapFaultyBookings?sortBy=${sortBy}`, data);
  } else {
    delete data['page'];
    return post(`/charger_bookings/sapFaultyBookings?page=${parseInt(page)}`, data);
  }
};




export const getSapOcpiPartnerList = (data) =>{
  return get('/sapOcpiPartner/list', data)
}

export const fetchSapOcpiCredList = (data) =>{
  return get('/sapOcpiPartner/ocpi-credentials', data)
}

export const addSapOcpiCredList = (data) =>{
  return post('/sapOcpiPartner/create-Sap-Ocpi-Partner', data)
}

export const updateSapOcpiCredList = (data) =>{
  const id = data.id
  return patch(`/sapOcpiPartner/update/${id}`, data)
}

export const deleteSapOcpiCredList = (id) =>{
  return deleteRequest(`/sapOcpiPartner/delete/${id}`)
}

export const generateSapInvoicesWithGst = (data) =>{
  return post('/sapOcpiPartner/generate-statewise-invoices', data)
}


export const fetchSapInvoicesWithGst = (data) =>{
  return post('/sapOcpiPartner/generate-invoices-with-gst', data)
}

export const downloadSapInvoicesWithGst = (data) =>{
  return downloadFile('/sapOcpiPartner/generate-invoices-with-gst', data)
}

export const triggerLogitaxOcpiInvoices = (data) =>{
  return post('/sapOcpiPartner/trigger-logitax-ocpi-invoices', data)
}


export const sap = {
  sendSAPInvoiceB2CRecordsCGSTSGST,
  sendSAPInvoiceB2CRecordsIGST,
  getSapMonthlyData,
  downloadInvoiceB2C,
  sapFaultyBookingReport,
  getSapOcpiPartnerList,
  fetchSapOcpiCredList,
  addSapOcpiCredList,
  updateSapOcpiCredList,
  deleteSapOcpiCredList,
  generateSapInvoicesWithGst,
  downloadSapInvoicesWithGst,
  triggerLogitaxOcpiInvoices,
  fetchSapInvoicesWithGst
};
