import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import {
  FETCH_SAP_MONTHLY_DATA,
  SEND_SAP_INVOICE_B2C_RECORDS_CGST_CGST,
  SEND_SAP_INVOICE_B2C_RECORDS_IGST,
  DOWNLOAD_INVOICE_B2C,
  SAP_FAULTY_BOOKING_REPORT,
  GET_SAP_OCPI_PARTNER_LIST,
  FETCH_SAP_OCPI_CREDS_LIST,
  ADD_SAP_OCPI_PARTNER_LIST,
  UPDATE_SAP_OCPI_PARTNER_LIST,
  DELETE_SAP_OCPI_PARTNER_LIST,
  GENERATE_SAP_INVOICES_WITH_GST,
  DOWNLOAD_SAP_INVOICES_WITH_GST,
  TRIGGER_LOGITAX_OCPI_INVOICES,
  FETCH_SAP_INVOICES_WITH_GST
} from 'actions/sap';

function* fetchSapMonthlyData(action) {
  try {
    const response = yield call(API.getSapMonthlyData, action.payload);
    yield put({ type: FETCH_SAP_MONTHLY_DATA.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_SAP_MONTHLY_DATA.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* downloadInvoiceB2C(action) {
  try {
    const response = yield call(API.downloadInvoiceB2C, action.payload);
    yield put({ type: DOWNLOAD_INVOICE_B2C.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_INVOICE_B2C.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* sendSAPInvoiceB2CRecordsIGST(action) {
  try {
    const response = yield call(API.sendSAPInvoiceB2CRecordsIGST, action.payload);
    yield put({ type: SEND_SAP_INVOICE_B2C_RECORDS_IGST.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: SEND_SAP_INVOICE_B2C_RECORDS_IGST.FAIL, payload: { error: e } });
  }
}

function* sendSAPInvoiceB2CRecordsCGSTSGST(action) {
  try {
    const response = yield call(API.sendSAPInvoiceB2CRecordsCGSTSGST, action.payload);
    yield put({ type: SEND_SAP_INVOICE_B2C_RECORDS_CGST_CGST.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: SEND_SAP_INVOICE_B2C_RECORDS_CGST_CGST.FAIL, payload: { error: e } });
  }
}

function* sapFaultyBookingReport(action) {
  try {
    const response = yield call(API.sapFaultyBookingReport, action.payload);
    yield put({ type: SAP_FAULTY_BOOKING_REPORT.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: SAP_FAULTY_BOOKING_REPORT.FAIL, payload: { error: e } });
  }
}


//ocpi related items

function* getSapOcpiPartnerList(action) {
  try {
    const response = yield call(API.getSapOcpiPartnerList, action.payload);
    yield put({ type: GET_SAP_OCPI_PARTNER_LIST.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: GET_SAP_OCPI_PARTNER_LIST.FAIL, payload: { error: e } });
  }
}


function* fetchSapOcpiCredList(action) {
  try {
    const response = yield call(API.fetchSapOcpiCredList, action.payload);
    yield put({ type: FETCH_SAP_OCPI_CREDS_LIST.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_SAP_OCPI_CREDS_LIST.FAIL, payload: { error: e } });
  }
}

function* addSapOcpiCredList(action) {
  try {
    const response = yield call(API.addSapOcpiCredList, action.payload);
    yield put({ type: ADD_SAP_OCPI_PARTNER_LIST.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: ADD_SAP_OCPI_PARTNER_LIST.FAIL, payload: { error: e } });
  }
}

function* updateSapOcpiCredList(action) {
  try {
    const response = yield call(API.updateSapOcpiCredList, action.payload);
    yield put({ type: UPDATE_SAP_OCPI_PARTNER_LIST.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_SAP_OCPI_PARTNER_LIST.FAIL, payload: { error: e } });
  }
}

function* deleteSapOcpiCredList(action) {
  try {
    const response = yield call(API.deleteSapOcpiCredList, action.payload);
    yield put({ type: DELETE_SAP_OCPI_PARTNER_LIST.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_SAP_OCPI_PARTNER_LIST.FAIL, payload: { error: e } });
  }
}

function* generateSapInvoicesWithGst(action) {
  try {
    const response = yield call(API.generateSapInvoicesWithGst, action.payload);
    yield put({ type: GENERATE_SAP_INVOICES_WITH_GST.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: GENERATE_SAP_INVOICES_WITH_GST.FAIL, payload: { error: e } });
  }
}

function* downloadSapInvoicesWithGst(action) {
  try {
    const response = yield call(API.downloadSapInvoicesWithGst, action.payload);
    yield put({ type: DOWNLOAD_SAP_INVOICES_WITH_GST.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_SAP_INVOICES_WITH_GST.FAIL, payload: { error: e } });
  }
}

function* fetchSapInvoicesWithGst(action) {
  try {
    const response = yield call(API.fetchSapInvoicesWithGst, action.payload);
    yield put({ type: FETCH_SAP_INVOICES_WITH_GST.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_SAP_INVOICES_WITH_GST.FAIL, payload: { error: e } });
  }
}

function* triggerLogitaxOcpiInvoices(action) {
  try {
    const response = yield call(API.triggerLogitaxOcpiInvoices, action.payload);
    yield put({ type: TRIGGER_LOGITAX_OCPI_INVOICES.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: TRIGGER_LOGITAX_OCPI_INVOICES.FAIL, payload: { error: e } });
  }
}


function* sap() {
  yield takeLatest(FETCH_SAP_MONTHLY_DATA.REQUEST, fetchSapMonthlyData);
  yield takeLatest(SEND_SAP_INVOICE_B2C_RECORDS_IGST.REQUEST, sendSAPInvoiceB2CRecordsIGST);
  yield takeLatest(SEND_SAP_INVOICE_B2C_RECORDS_CGST_CGST.REQUEST, sendSAPInvoiceB2CRecordsCGSTSGST);
  yield takeLatest(DOWNLOAD_INVOICE_B2C.REQUEST, downloadInvoiceB2C);
  yield takeLatest(SAP_FAULTY_BOOKING_REPORT.REQUEST, sapFaultyBookingReport);

//ocpi related items

  yield takeLatest(GET_SAP_OCPI_PARTNER_LIST.REQUEST, getSapOcpiPartnerList);
  yield takeLatest(FETCH_SAP_OCPI_CREDS_LIST.REQUEST, fetchSapOcpiCredList);
  yield takeLatest(ADD_SAP_OCPI_PARTNER_LIST.REQUEST, addSapOcpiCredList);
  yield takeLatest(UPDATE_SAP_OCPI_PARTNER_LIST.REQUEST, updateSapOcpiCredList);
  yield takeLatest(DELETE_SAP_OCPI_PARTNER_LIST.REQUEST, deleteSapOcpiCredList);
  yield takeLatest(GENERATE_SAP_INVOICES_WITH_GST.REQUEST, generateSapInvoicesWithGst);
  yield takeLatest(DOWNLOAD_SAP_INVOICES_WITH_GST.REQUEST, downloadSapInvoicesWithGst);
  yield takeLatest(FETCH_SAP_INVOICES_WITH_GST.REQUEST, fetchSapInvoicesWithGst);
  yield takeLatest(TRIGGER_LOGITAX_OCPI_INVOICES.REQUEST, triggerLogitaxOcpiInvoices);}
export default sap;
