import React, { useState, useEffect, useCallback } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header';
import Card from 'components/inputs/Card';
import DateTimePicker from 'components/inputs/DateTimePicker';
import { Col, Row, Modal, Button } from 'react-bootstrap';
import moment from 'moment-timezone';
import * as _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { GET_SMC_PAYMENT_GATEWAY, FETCH_VOUCHER_LIST, VIEW_VOUCHER, UPDATE_VOUCHER_NUMBER } from 'actions/smcGateway';
import { useTranslation } from 'react-i18next';
import Footer from 'components/general/Footer';
import { MdClose } from 'react-icons/md';
import CustomDatePickerInput from 'components/inputs/DatePickerInput';
import { toast } from 'react-toastify';

const VoucherAssignments = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isDate, setIsDate] = useState(false);
  const [isVoucher, setIsVoucher] = useState(false);
  const [showModal, setShowModal] = useState(false); // State for modal visibility
  const [selectedReportId, setSelectedReportId] = useState(null); // State for the selected report ID
  const [voucherNumber, setVoucherNumber] = useState(''); // State for voucher number
  const [voucherDate, setVoucherDate] = useState('');
  const [pdfUrl, setPdfUrl] = useState(null);
  const [showPdfModal, setShowPdfModal] = useState(false);
  const [loadingReportId, setLoadingReportId] = useState(null);
  const [pdfPreviewId, setpdfPreviewId] = useState(null);

  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserName = _.get(profileData, 'name', '');

  const reportList = useSelector((state) => state.smcReports.reports);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const handleAssignVoucherClick = (reportId, voucherNumber, voucherDate) => {
    setSelectedReportId(reportId); // Set the selected report ID
    setIsDate(voucherDate)
    setIsVoucher(voucherNumber)
    setShowModal(true); // Show the modal
  };

  const handleModalClose = () => {
    setShowModal(false); // Close the modal
    setVoucherNumber(''); // Reset the voucher number
  };

  const handleVoucherSubmit = () => {

    if ((isVoucher && !voucherNumber.trim()) || (isDate && !voucherDate)) {
      toast.error("Voucher Number and Date cannot be empty.");
      return;
    }

    dispatch({
      type: UPDATE_VOUCHER_NUMBER.REQUEST,
      payload: {
        reportId: selectedReportId,
        voucherNumber,
        voucherDate
      },
      cb: (res) => {
        if (res && res.status === 200) {
          toast.success("Voucher updated successfully!");
          fetchAllVoucherList({});
        } else {
          toast.error("Failed to update voucher. Please try again.");
        }
        handleModalClose(); // Close the modal after handling response
      },
    });
  };


  const getSMCGateway = useCallback((data) => {
    dispatch({ type: GET_SMC_PAYMENT_GATEWAY.REQUEST, payload: data });
  }, []);

  useEffect(() => {
    getSMCGateway();
  }, []);

  //FETCH VOUCHER LIST DAY WISE
  const fetchAllVoucherList = (data) => {

    const today = moment().utc().startOf('day'); // Convert today to UTC start of day
    const start = moment(startDate).utc().startOf('day'); // Convert startDate to UTC start of day
    const end = moment(endDate).utc().startOf('day'); // Convert endDate to UTC start of day

    // Block today from being selected
    if (start.isSame(today, 'day') || end.isSame(today, 'day')) {
      toast.error("Today's date selection is not allowed");
      return; // Stop further execution
    }

    // Check if startDate is greater than endDate
    if (moment(startDate).isAfter(moment(endDate))) {
      toast.error("From date cannot be greater than To date");
      return; // Stop further execution
    }

    if (startDate && endDate) {

      const payload = {
        ...data,
        from: moment(startDate).utc().startOf('day'), // 00:00:00.000
        to: moment(endDate).utc().endOf('day'), // 23:59:58.999
        status: ['approved']
      };

      dispatch({
        type: FETCH_VOUCHER_LIST.REQUEST,
        payload,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              toast.success("Approved Vouchers Fetched Successfully")
            }
          }
        },
      });
    }
    else {
      const payload = {
        ...data,
        status: ['approved']
      };

      dispatch({
        type: FETCH_VOUCHER_LIST.REQUEST,
        payload,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              toast.success("Approved Vouchers Fetched Successfully")
            }
          }
        },
      });
    }


  };

  //VIEW PDF PERVIEW
  const viewVoucherPreview = (reportId) => {

    setLoadingReportId(reportId); // Set loading state

    const payload = { reportId, pdfPreviewId };

    dispatch({
      type: VIEW_VOUCHER.REQUEST,
      payload,
      cb: (res) => {
        setLoadingReportId(null); // Reset loading state
        if (res && _.get(res, 'status') === 200) {
          const blob = new Blob([res.data], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);
          setPdfUrl(url);
          setShowPdfModal(true);
          setpdfPreviewId(reportId)
        }
      },
    });
  };

  useEffect(() => {
    fetchAllVoucherList({});
  }, []);

  useEffect(() => {
    // Clear report list on page load
    dispatch({
      type: FETCH_VOUCHER_LIST.SUCCESS,
      payload: [],
    });
  }, []);

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.voucherAssignment')} />
        <div className="page-content-wrapper scrollable">
          <div className="data-report--main">
            <Card>
              <div className="data-report__inner">
                <div className="data-report__box">
                  <Row className="data-report__row">
                    <Col lg={'auto'}>
                      <div className="data-report-date_picker">
                        <DateTimePicker
                          onChangeOfStartDate={(item) => setStartDate(item)}
                          onChangeOfEndDate={(item) => setEndDate(item)}
                          initialValueOfStartDate={''} // Yesterday as default start date
                          initialValueOfEndDate={''} // Yesterday as default end date
                          minDate={moment().add(1, 'days').startOf('day')} // Prevent selection of today
                          closeOnSelect={true}
                          timeFormat={false} // Disable time selection
                        />
                      </div>
                    </Col>
                    <Col lg={'auto'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Button type="submit" title='Show to be assign vouchers' className="apply--btn w-100" onClick={() => { fetchAllVoucherList({}) }}>
                        {t('Submit')}
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
            </Card>
          </div>
          <div className="data-table-wrapper mt-4">
            <h5>{t('Report List')}</h5>
            <table className="record-list-table" id="table-to-xls">
              <thead>
                <tr>
                  <th>{t('Type')}</th>
                  <th>{t('Date')}</th>
                  <th>{t('Transaction ID')}</th>
                  <th>{t('Status')}</th>
                  <th>{t('Assign Date')}</th>
                  <th>{t('Voucher Number')}</th>
                  <th>{t('Actions')}</th>
                  <th>{t('Assignment Status')}</th>
                </tr>
              </thead>
              <tbody>
                {reportList && reportList.length > 0 ? (
                  reportList
                    .filter((item) => !item.voucherNumber) // Exclude items that already have a voucherNumber
                    .map((item) => (
                      <tr key={item.reportId}>
                        <td>{item.reportType ? item.reportType : '-'}</td>
                        <td>{item?.dateRange?.from ? moment(item?.dateRange?.from).format('DD-MM-YYYY') : '-'}</td>
                        <td>{item.transactionId ? item.transactionId : '-'}</td>
                        <td>{item.status ? item.status : '-'}</td>
                        <td>{item.voucherDate ? moment(item.voucherDate).format('DD-MM-YYYY') : '-'}</td>
                        <td>{item.voucherNumber ? item.voucherNumber : '-'}</td>
                        <td>
                          <button
                            className="job-report-button"
                            onClick={() => viewVoucherPreview(item._id)}
                            disabled={loadingReportId === item._id}
                          >
                            {loadingReportId === item._id ? t('Loading...') : t('View')}
                          </button>
                        </td>
                        {(!item.voucherNumber || !item.voucherDate) && (
                          <td>
                            <button
                              className="job-report-button responsive-button"
                              onClick={() => handleAssignVoucherClick(
                                item._id,
                                item.voucherNumber ? false : true,
                                item.voucherDate ? false : true
                              )}
                            >
                              {t(
                                item.voucherNumber
                                  ? 'Assign Voucher Date'
                                  : item.voucherDate
                                    ? 'Assign Voucher Number'
                                    : 'Assign Voucher Number and Date'
                              )}
                            </button>
                          </td>
                        )}
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td colSpan="8" className="text-center">
                      {t('No reports available')}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <Modal size={'auto'} show={showModal} onHide={handleModalClose} centered className="job-modal">
            <Modal.Header>
              <div className='job-modal-header'>
                <Modal.Title>{t('View Voucher Details')}</Modal.Title>
                <button onClick={handleModalClose}>
                  <MdClose />
                </button>
              </div>
            </Modal.Header>
            <Modal.Body className='job-modal-body'>
              {isVoucher && <input
                type="text"
                className="form-control mt-2"
                value={voucherNumber}
                onChange={(e) => setVoucherNumber(e.target.value)}
                placeholder={t('Enter Voucher Number')}
                label={t('Enter Voucher Number')}
              />}
              {isDate && <CustomDatePickerInput
                placeholder={t('Enter Voucher Date')}
                label={t('Enter Voucher Date')}
                name="voucherDate"
                value={voucherDate}
                onChange={(date) => setVoucherDate(date)}
              />}
            </Modal.Body>
            <Modal.Footer className='job-modal-footer'>
              <Button onClick={handleVoucherSubmit} className='job-report-button'>
                {t('Submit')}
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showPdfModal} className="job-modal" onHide={() => setShowPdfModal(false)} size="lg" centered>
            <Modal.Header closeButton>
              <Modal.Title>{t('PDF Preview')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='pdfPreview' style={{ overflow: 'hidden', display: 'flex' }}>
                {pdfUrl ? (
                  <iframe
                    src={pdfUrl}
                    width="100%"
                    height="700px"
                    style={{ border: 'none', transform: 'translateY(-70px)' }}
                    title="PDF Report Preview"
                  ></iframe>
                ) : (
                  <p>{t('Loading PDF...')}</p>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowPdfModal(false)}>
                {t('Close')}
              </Button>
            </Modal.Footer>
          </Modal>



          {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
        </div>
      </LayoutContainer>
    </>
  );
};

export default VoucherAssignments;