import React, { useState, useEffect, useCallback } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header';
import Card from 'components/inputs/Card';
import DateTimePicker from 'components/inputs/DateTimePicker';
import { Col, Row, Modal, Button } from 'react-bootstrap';
import moment from 'moment-timezone';
import * as _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { GET_SMC_PAYMENT_GATEWAY, FETCH_VOUCHER_LIST, ASSIGN_VOUCHER_DETAILS, SUBMIT_VOUCHER_STATUS, VIEW_VOUCHER } from 'actions/smcGateway';
import { useTranslation } from 'react-i18next';
import Footer from 'components/general/Footer';
import { MdClose } from 'react-icons/md';
import { getTimezoneFromCountryCode } from 'utils/timezone/timezoneUtils';
import CustomDatePickerInput from 'components/inputs/DatePickerInput';
import { toast } from 'react-toastify';
import fileDownload from 'js-file-download';
import TextInput from 'components/inputs/Input';


const rejectedVoucher = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isDate, setIsDate] = useState(false);
    const [isVoucher, setIsVoucher] = useState(false);
    const [showModal, setShowModal] = useState(false); // State for modal visibility
    const [selectedReportId, setSelectedReportId] = useState(null); // State for the selected report ID
    const [voucherNumber, setVoucherNumber] = useState(''); // State for voucher number
    const [voucherDate, setVoucherDate] = useState('');
    const [pdfUrl, setPdfUrl] = useState(null);
    const [showPdfModal, setShowPdfModal] = useState(false);
    const [loadingReportId, setLoadingReportId] = useState(null);
    const profileData = useSelector((state) => state.profile.userProfile);
    const currentUserName = _.get(profileData, 'name', '');
    const reportList = useSelector((state) => state.smcReports.reports);
    const [pdfPreviewId, setpdfPreviewId] = useState(null);
    const countryTimezone = getTimezoneFromCountryCode(_.get(profileData, 'country_code', 'UTC'));
    const [startDate, setStartDate] = useState(moment(new Date()).tz(countryTimezone).startOf('day').utc());
    const [endDate, setEndDate] = useState(moment(new Date()).tz(countryTimezone).endOf('day').utc());
    const [matchAmount, setMatchAmount] = useState(''); // State to store match amount
    const [reportTypePreview, setReportTypePreview] = useState('');
    const [voucherTypePreview, setVoucherTypePreview] = useState('');
    const [reportStatus, setReportStatus] = useState('');


    const handleAssignVoucherClick = (reportId, voucherNumber, voucherDate) => {
        setSelectedReportId(reportId); // Set the selected report ID
        setIsDate(voucherDate)
        setIsVoucher(voucherNumber)
        setShowModal(true); // Show the modal
    };

    const handleModalClose = () => {
        setShowModal(false); // Close the modal
        setVoucherNumber(''); // Reset the voucher number
    };
    const handleVoucherSubmit = () => {
        dispatch({
            type: ASSIGN_VOUCHER_DETAILS.REQUEST,
            payload: {
                reportId: selectedReportId,
                voucherNumber,
                voucherDate
            },
        });
        handleModalClose(); // Close the modal after dispatching
    };

    const initialValues = {
        from: moment(startDate).tz(countryTimezone).utc(),
        to: moment(endDate).tz(countryTimezone).utc(),
    };

    const getSMCGateway = useCallback((data) => {
        dispatch({ type: GET_SMC_PAYMENT_GATEWAY.REQUEST, payload: data });
    }, []);

    useEffect(() => {
        getSMCGateway();
    }, []);


    //FETCH VOUCHER LIST DAY WISE ONLY REJECTED AND PENDING WITH OUT ANY DATE FILTER
    const fetchAllVoucherList = (data) => {
        const payload = {
            ...data,
            status: ['rejected', 'pending']
        };

        dispatch({
            type: FETCH_VOUCHER_LIST.REQUEST,
            payload,
            cb: (res) => {
                if (res) {
                    if (_.get(res, 'status') === 200) {
                        toast.success("Rejeceted and Pending Vouchers Fetched Successfully")
                    }
                }
            },
        });
    };

    //VIEW PDF
    const viewVoucherPreview = (reportId, type, reportType, status) => {
        setLoadingReportId(reportId); // Set loading state
        setReportTypePreview(reportType);
        setVoucherTypePreview(type);
        setReportStatus(status)
        setMatchAmount('')

        const payload = { reportId };

        dispatch({
            type: VIEW_VOUCHER.REQUEST,
            payload,
            cb: (res) => {
                setLoadingReportId(null); // Reset loading state
                if (res && _.get(res, 'status') === 200) {
                    const blob = new Blob([res.data], { type: 'application/pdf' });
                    const url = URL.createObjectURL(blob);
                    setPdfUrl(url);
                    setShowPdfModal(true);
                    setpdfPreviewId(reportId)
                }
            },
        });
    };


    //SEND STATUS
    const handleVoucherStatus = (status) => {
        let payload = {
            reportId: pdfPreviewId,
            status: status, // "Approved", "Rejected", or "Pending"
        };

        // If status is NOT 'pending', include additional data
        if (status !== 'pending') {
            const numericMatchAmount = Number(matchAmount);

            if ((isNaN(numericMatchAmount) || matchAmount === '' || matchAmount === null) && (status === 'approved')) {
                toast.error("Please enter a valid amount.");
                return;
            }

            payload = {
                ...payload,
                approvedBy: status === 'approved' ? profileData?.name : undefined,
                rejectedBy: status === 'rejected' ? profileData?.name : undefined, // Include rejectedBy if status is "rejected"
                matchAmount: numericMatchAmount, // Send matchAmount only if not pending
                reportType: reportTypePreview || '',
                voucherType: voucherTypePreview || ''
            };
        }

        dispatch({
            type: SUBMIT_VOUCHER_STATUS.REQUEST,
            payload,
            cb: (res) => {
                if (res && _.get(res, 'status') === 200) {
                    if (status === 'rejected') {
                        toast.success("Voucher Rejected Successfully");
                    } else if (status === 'pending') {
                        toast.success("Voucher Status set to Pending");
                    } else {
                        fileDownload(res.data, `voucher_status_${status}.pdf`);
                        toast.success("Voucher Approved and Downloaded Successfully");
                    }

                    setShowPdfModal(false);
                    fetchAllVoucherList({});
                }
            },
        });
    };



    useEffect(() => {
        fetchAllVoucherList(); // Pass the updated status to the function
    }, []); // Re-run when `status` changes


    useEffect(() => {
        // Clear report list on page load
        dispatch({
            type: FETCH_VOUCHER_LIST.SUCCESS,
            payload: [],
        });
    }, []);
    return (
        <>
            <LayoutContainer>
                <AdminHeader title={t('header.rejectedVoucher')} />
                <div className="page-content-wrapper scrollable">
                    <div className="data-report--main">
                    </div>
                    <div className="data-report--main" style={{ display: 'none' }}>
                        <Card>
                            <div className="data-report__inner">
                                <div className="data-report__box">
                                    <Row className="data-report__row">
                                        <Col lg={'auto'}>
                                            <div className="data-report-date_picker">
                                                <DateTimePicker
                                                    onChangeOfStartDate={(item) => setStartDate(item)}
                                                    onChangeOfEndDate={(item) => setEndDate(item)}
                                                    initialValueOfStartDate={initialValues} // Yesterday as default start date
                                                    initialValueOfEndDate={initialValues} // Yesterday as default end date
                                                    minDate={moment().add(1, 'days').startOf('day')} // Prevent selection of today
                                                    closeOnSelect={true}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className="data-table-wrapper mt-4">
                        <h5>{t('Rejected and Pending Voucher List')}</h5>
                        <table className="record-list-table" id="table-to-xls">
                            <thead>
                                <tr>
                                    <th>{t('Type')}</th>
                                    <th>{t('Date')}</th>
                                    <th>{t('Transaction ID')}</th>
                                    <th>{t('Status')}</th>
                                    <th>{t('Rejected Count')}</th>
                                    <th>{t('Rejected By')}</th>
                                    <th>{t('Actions')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {reportList && reportList.length > 0 ? (
                                    reportList.map((item) => (
                                        <tr key={item.reportId}>
                                            <td>{item.reportType}</td>
                                            <td>
                                                {moment(item?.dateRange?.from).format('DD-MM-YYYY')}
                                            </td>
                                            <td>{item.transactionId}</td>
                                            <td>{item.status}</td>
                                            <td>{item.rejectCount}</td>
                                            <td>{item.rejectedBy}</td>
                                            <td>
                                                <button
                                                    className="job-report-button"
                                                    onClick={() => viewVoucherPreview(item._id, item.type, item.reportType, item.status)}
                                                    disabled={loadingReportId === item._id} // Disable button while loading
                                                >
                                                    {loadingReportId === item._id ? t('Loading...') : t('View')}
                                                </button>
                                            </td>
                                            {(!item.voucherNumber || !item.voucherDate) && (
                                                <td style={{ display: 'none' }}>
                                                    <button
                                                        className="job-report-button responsive-button"
                                                        onClick={() => handleAssignVoucherClick(item.reportId, item.voucherNumber ? false : true, item.voucherDate ? false : true)}
                                                    >
                                                        {t(item.voucherNumber ? 'Assign Voucher Date' : item.voucherDate ? 'Assign Voucher Number' : 'Assign Voucher Number and Date')}
                                                    </button>
                                                </td>
                                            )}
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="5" className="text-center">
                                            {t('No reports available')}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>

                    <Modal size={'auto'} show={showModal} onHide={handleModalClose} centered className="job-modal">
                        <Modal.Header>
                            <div className='job-modal-header'>
                                <Modal.Title>{t('View Voucher Details')}</Modal.Title>
                                <button onClick={handleModalClose}>
                                    <MdClose />
                                </button>
                            </div>
                        </Modal.Header>
                        <Modal.Body className='job-modal-body'>
                            {isVoucher && <input
                                type="text"
                                className="form-control mt-2"
                                value={voucherNumber}
                                onChange={(e) => setVoucherNumber(e.target.value)}
                                placeholder={t('Enter Voucher Number')}
                            />}
                            {isDate && <CustomDatePickerInput
                                placeholder={t('offerForm.voucherDate')}
                                label={t('offerForm.voucherDate')}
                                name="voucherDate"
                                value={voucherDate}
                                onChange={(date) => setVoucherDate(date)}
                            />}
                        </Modal.Body>
                        <Modal.Footer className='job-modal-footer'>
                            <Button onClick={handleVoucherSubmit} className='job-report-button'>
                                {t('Submit')}
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={showPdfModal} className="job-modal" onHide={() => setShowPdfModal(false)} size="lg" centered>
                        <Modal.Header closeButton>
                            <Modal.Title>{t('PDF Preview')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='pdfPreview' style={{ overflow: 'hidden', display: 'flex' }}>
                                {pdfUrl ? (
                                    <iframe
                                        src={pdfUrl}
                                        width="100%"
                                        height="700px"
                                        style={{ border: 'none', transform: 'translateY(-70px)' }}
                                        title="PDF Report Preview"
                                    ></iframe>
                                ) : (
                                    <p>{t('Loading PDF...')}</p>
                                )}

                                <div className='approvalBlock' style={{ marginLeft: '10px', display: 'flex', flexDirection: 'column' }}>
                                    {/* Show TextInput only if status is not 'rejected' */}
                                    {reportStatus !== 'rejected' && (
                                        <TextInput
                                            isRequired
                                            label={t('Match Amount')}
                                            placeholder={t('Enter Amount')}
                                            name="consumerNumber"
                                            value={matchAmount}
                                            onChange={(e) => setMatchAmount(e.target.value)}
                                        />
                                    )}

                                    {/* Approve button (only if status is not 'rejected') */}
                                    {reportStatus !== 'rejected' && (
                                        <button
                                            className="job-report-button responsive-button"
                                            style={{ margin: '10px 0px' }}
                                            onClick={() => handleVoucherStatus('approved')}
                                        >
                                            {t('Approve')}
                                        </button>
                                    )}

                                    {/* Toggle between "Reject" and "Pending" */}
                                    <button
                                        className="job-report-button responsive-button"
                                        onClick={() => handleVoucherStatus(reportStatus === 'rejected' ? 'pending' : 'rejected')}
                                    >
                                        {t(reportStatus === 'rejected' ? 'Convert to Pending' : 'Reject')}
                                    </button>
                                </div>

                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowPdfModal(false)}>
                                {t('Close')}
                            </Button>
                        </Modal.Footer>
                    </Modal>



                    {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
                </div>
            </LayoutContainer>
        </>
    );
};

export default rejectedVoucher;